import React, { useEffect, useState } from 'react';
import { useRequireAuth } from '../hooks/UseRequireAuth.js';
import { Button, Container, Spinner, Table } from 'react-bootstrap';
import { useError } from '../contexts/ErrorContext.js';
import { useTranslation } from 'react-i18next';
import { getMediasTable } from '../apis/AxiosLaravel.js';
import { faEdit, faEye, faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { genreDisplay, getFSKClass, getPosterSRC } from '../utils/media.js';
import TextClamp from 'react-string-clamp';
import { LinkContainer } from 'react-router-bootstrap';
import PaginationComponent from '../components/media/PaginationComponent.js';
import DeleteModalComponent from '../components/DeleteModalComponent.js';
import EditModalComponent from '../components/media/EditModalComponent.js';
import LocationModalComponent from '../components/LocationModalComponent.js';
import KeywordModalComponent from '../components/KeywordModalComponent.js'

/**
 * Component that represents the media management page of the site
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MediaManagement = (props) => {
  const auth = useRequireAuth(['Admin', 'Creator']);
  const [media, setMedia] = useState({ res: null, status: false });
  const [errorMediaFetch, setErrorMediaFetch] = useState(false);
  const [page, setPage] = useState(1);
  const [deleteShow, setDeleteShow] = useState({ show: false, title: '', id: '' });
  const [locationShow, setLocationShow] = useState({ show: false });
  const [keywordShow, setKeywordShow] = useState({ show: false });
  const [editShow, setEditShow] = useState({ show: false, media: '' });
  const { t } = useTranslation();
  const errorContext = useError();

  // Array of headers for the table
  const tableHeaders = [
    t('media:table.poster-header'),
    t('media:table.type-header'),
    t('media:table.age-rating-header'),
    t('media:table.title-header'),
    t('media:table.overview-header'),
    t('media:table.genres-header'),
    t('media:table.mediums-header'),
    t('media:table.seasons-header'),
    t('media:table.display-header'),
    t('media:table.edit-header'),
    t('media:table.delete-header'),
  ];

  /**
   * Wrapper for the setPage useState setter
   * @param pageNumber
   */
  const updatePage = (pageNumber) => {
    setPage(pageNumber);
  };

  /**
   * Method that loads the data for table
   */
  const loadMedia = () => {
    setMedia({
      ...media,
      status: false,
    });
    getMediasTable(page)
      .then(res => {
        setMedia({ res, status: true });
      })
      .catch(error => {
        setMedia({
          ...media,
          status: true,
        });
        setErrorMediaFetch(true);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  /**
   * On page change useEffect is calls the loadMedia method
   */
  useEffect(() => {
    loadMedia();
  }, [page]);

  if (!auth) {
    return (
      <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <React.Fragment>
        <Container id="mdb-media-table">
          {
            errorContext.alert()
          }
          <h2 className="mdb-page-header mdb-card-style mt-3 p-2">{t('media-management-page-header')}</h2>
          <Container className="mdb-media-table mdb-card-style mb-3">
            {
              !media.status && !errorMediaFetch && <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
                <span className="sr-only">Loading...</span>
              </Spinner>
            }

            <div className='d-flex'>
              <Button
                className="mdb-show-locations-button mb-2 mt-2"
                type="submit" variant="outline-primary"
                onClick={() => setLocationShow({ show: true })}
              s>
                {t('media:add-media.show-locations')}
              </Button>
              <Button
                className="mdb-show-keywords-button mb-2 mt-2 ml-2"
                type="submit" variant="outline-primary"
                onClick={() => setKeywordShow({ show: true })}
              s>
                {t('media:add-media.show-keywords')}
              </Button>
            </div>
            

            {
              media.status &&
              <Table striped responsive>
                <thead className="text-center">
                <tr>
                  <th>Id</th>
                  {tableHeaders.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {
                  media?.res?.data?.map((el, index) => {

                    return (
                      <tr key={index}>
                        <td>{el.id}</td>
                        <td><img className="mdb-table-poster" src={getPosterSRC(el.tmdb_id, el.poster_path, 'w92')}/>
                        </td>
                        <td>{el.type}</td>
                        <td>
                          <div className={`mdb-fsk ${getFSKClass(el.age_rating)}`}>{el.age_rating}</div>
                        </td>
                        <td className="mdb-table-title">{el.title}</td>
                        <td>
                          <TextClamp text={el.overview} lines={4}/>
                        </td>
                        <td>{genreDisplay(el.genres, t)}</td>
                        <td>{el.mediums.join(', ')}</td>
                        <td>{el.seasons?.join(', ')}</td>
                        <td className="mdb-table-display"><LinkContainer to={`/media/${el.id}`}><FontAwesomeIcon
                          icon={faEye}/></LinkContainer></td>
                        <td className="mdb-table-edit" aria-label={`Edit ${el.title}`}
                            onClick={() => setEditShow({ show: true, media: el })}><FontAwesomeIcon
                          icon={faEdit}/>
                        </td>
                        <td className="mdb-table-delete" aria-label={`Delete ${el.title}`}
                            onClick={() => setDeleteShow({ show: true, title: el.title, id: el.id })}><FontAwesomeIcon
                          icon={faTrash}/></td>
                      </tr>
                    );
                  })
                }
                <tr>
                  <LinkContainer to="/add-media">
                    <td colSpan="12" aria-controls="button" className="mdb-table-add-new">
                      <FontAwesomeIcon className="mr-2" icon={faPlusSquare}/>{t('media:add-media.add-new-media')}
                    </td>
                  </LinkContainer>

                </tr>
                </tbody>
              </Table>
            }
          </Container>
          <PaginationComponent response={media?.res} update={updatePage}/>
        </Container>
        <LocationModalComponent
          show={locationShow.show}
          onHide={() => setLocationShow({
            show: false,
          })}
        />
        <KeywordModalComponent
          show={keywordShow.show}
          onHide={() => setKeywordShow({
            show: false,
          })}
        />
        <EditModalComponent
          media={editShow.media}
          reloadMedia={loadMedia}
          show={editShow.show}
          onHide={() => setEditShow({
            show: false,
            media: null,
          })}/>
        <DeleteModalComponent
          type="media"
          title={deleteShow.title}
          param={deleteShow.id}
          reloadMedia={loadMedia}
          show={deleteShow.show}
          onHide={() => setDeleteShow({
            show: false,
            title: '',
            id: null,
          })}
        />
      </React.Fragment>

    );
  }
};

export default MediaManagement;