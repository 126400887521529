import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import EmptyMedia from './EmptyMedia.js';

/**
 * Wrapper component for empty media, that adds a link around it if the user is allowed to add new media
 * @param full
 * @returns {JSX.Element}
 * @constructor
 */
const NoMediaComponent = ({ full = false }) => {
  const auth = useAuth();

  return (
    <React.Fragment>
      {
        auth?.user &&  (auth?.user?.role === 'Admin' || auth?.user?.role === 'Creator') && <Link className="mdb-media-link" to={'/add-media'}>
          <EmptyMedia/>
        </Link>
      }
      {
        !auth?.user && <EmptyMedia noLink/>
      }
    </React.Fragment>
  );
};

export default NoMediaComponent;