import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMedia } from '../apis/AxiosLaravel.js';
import { Container, Spinner } from 'react-bootstrap';
import MediaComponent from '../components/media/MediaComponent.js';
import { useError } from '../contexts/ErrorContext.js';
import NoMediaComponent from '../components/media/NoMediaComponent.js';

const FullMedia = (props) => {
  const { id } = useParams();
  const [media, setMedia] = useState({ data: null, status: false });
  const errorContext = useError();

  /**
   * Only executed once on load. Loads media for specific id
   */
  useEffect(() => {
    getMedia(id)
      .then(res => {
        setMedia({ data: res.data, status: true });
      })
      .catch(error => {
        setMedia({ data: null, status: true });
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  }, []);


  return (
    <React.Fragment>
      <Container id="mdb-media-full">
        <h2 className="mdb-page-header mdb-card-style mt-3 p-2">{media?.data?.title}</h2>
        {
          !media.status &&
          <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
            <span className="sr-only">Loading...</span>
          </Spinner>
        }
        {
          media.data && media.status &&
          <MediaComponent media={media.data} full/>
        }
        {
          media.status && !media.data &&
          <NoMediaComponent full/>

        }
      </Container>
    </React.Fragment>
  );
};

export default FullMedia;