import Joi from "joi";

/**
 * Function to convert the genres from tmdb to lowercase with "-" instead of " "
 * Also conversion from german to english
 * @param genres
 * @returns {*}
 */
const genreConverter = (genres) => {
  const genreArray = genres.flatMap((genre) => {
    genre = genre.name.toLowerCase().replaceAll(" ", "-");

    switch (genre) {
      case "abenteuer":
        genre = "adventure";
        break;
      case "komödie":
        genre = "comedy";
        break;
      case "krimi":
        genre = "crime";
        break;
      case "dokumentarfilm":
        genre = "documentary";
        break;
      case "familie":
        genre = "family";
        break;
      case "historie":
        genre = "history";
        break;
      case "musik":
        genre = "music";
        break;
      case "liebesfilm":
        genre = "romance";
        break;
      case "tv-film":
        genre = "tv-movie";
        break;
      case "kriegsfilm":
        genre = "war";
        break;
    }
    return genre;
  });

  if (genreArray.includes("action-&-adventure")) {
    genreArray.splice(genreArray.indexOf("action-&-adventure"), 1);
    genreArray.push("action");
    genreArray.push("adventure");
  }
  if (genreArray.includes("war-&-politics")) {
    genreArray.splice(genreArray.indexOf("war-&-politics"), 1);
    genreArray.push("war");
    genreArray.push("politics");
  }
  if (genreArray.includes("sci-fi-&-fantasy")) {
    genreArray.splice(genreArray.indexOf("sci-fi-&-fantasy"), 1);
    genreArray.push("science-fiction");
    genreArray.push("fantasy");
  }

  return genreArray;
};

/**
 * Function to handle checkbox checked unchecked and adding or removing values from
 * corresponding array in data
 * @param name Array name
 * @param value The checkbox value
 * @param data Data that contains the array for the checkbox values
 * @param setData Function to set the data state
 */
const handleCheckBoxes = (name, value, data, setData) => {
  let temp = [...data[name]];
  if (data[name].includes(value)) {
    temp = data[name].filter((el) => el !== value);
  } else {
    temp.push(value);
  }

  setData({
    ...data,
    [name]: temp,
  });
};

/**
 * Filters cast array object elements
 * @param type movie or tv
 * @param cast Array with cast
 * @returns {*} Array containing casts
 */
const castFilter = (type, cast) => {
  type = type.toLowerCase();
  if (type === "movie") {
    cast = cast.map((actor) => {
      const { id, name, character, profile_path } = actor;
      return { id, name, character, profile_path };
    });
  } else if (type === "tv") {
    cast = cast.map((actor) => {
      const { id, name, profile_path } = actor;
      const roles = actor?.roles
        ?.filter((role) => !!role.character)
        .map((el) => el.character);
      return { id, name, roles, profile_path };
    });
  }

  return cast;
};

/**
 * Function to validate data with Joi schema
 * @param data Media data to be validated
 * @returns {Joi.ValidationError}
 */
const validateData = (data) => {
  const schema = Joi.object({
    type: Joi.string()
      .pattern(/(Movie|TV)/)
      .required(),
    title: Joi.string().required(),
    release_date: Joi.date().required(),
    poster_path: Joi.string()
      .allow("")
      .pattern(/\/(.)+\.(jpg)/)
      .optional(),
    overview: Joi.string().allow("").optional(),
    youtube_link: Joi.string()
      .allow("")
      .pattern(
        /^https:\/\/((www)?\.?)youtube\.com\/watch\?v=([a-zA-Z\d\-_&=])+$/
      )
      .optional(),
    age_rating: Joi.string()
      .pattern(/(0|6|12|16|18)/)
      .required(),
    location: Joi.string().required(),
    mediums: Joi.array().min(1).required(),
    genres: Joi.array().min(1).required(),
    seasons: Joi.array().optional(),
    keywords: Joi.array().optional(),
  }).unknown(true);

  const { error } = schema.validate(data, {
    abortEarly: false,
    dateFormat: "date",
    errors: { label: "key" },
  });

  return error;
};

/**
 * Function to get the video key from a youtube link
 * @param link Complete link https://www.youtube.de/watch?v=kjsficw34r
 * @returns {string}
 */
const getKeyFromYoutubeLink = (link) => {
  return link.substring(link.indexOf("=") + 1, link.length);
};

/**
 * Function that returns a boolean if the tmdb api key is provided or not
 * @returns {boolean}
 */
const tmdbActive = () => {
  return !!process.env.REACT_APP_TMDB_API_KEY;
};

export {
  genreConverter,
  castFilter,
  handleCheckBoxes,
  validateData,
  getKeyFromYoutubeLink,
  tmdbActive,
};
