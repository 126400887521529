import { Pagination } from 'react-bootstrap';
import React from 'react';

/**
 * Creates pagination elements and returns one element or an array of elements
 * @param current
 * @param lastPage
 * @param update Function that should be executed on click on a pagination link
 * to update the page number
 * @returns {JSX.Element|*[]}
 */
const getItems = (current, lastPage, update) => {
  const nextItems = 3;
  const advanceFromCurrent = 2;
  const items = [];

  if (lastPage === 1) {
    return (
      <Pagination.Item active>{current}</Pagination.Item>
    );
  }

  for (let n = 1; n <= lastPage; ++n) {
    if (n <= nextItems) {
      const item = <Pagination.Item key={n} active={current === n} onClick={() => {update(n)}}>{n}</Pagination.Item>;
      items.push(item);
    } else if ((lastPage - advanceFromCurrent) <= n) {
      const item = <Pagination.Item key={n} active={current === n} onClick={() => {update(n)}}>{n}</Pagination.Item>;
      items.push(item);
    } else if ( (current - advanceFromCurrent) <= n && n <= current ) {
      const item = <Pagination.Item key={n} active={current === n} onClick={() => {update(n)}}>{n}</Pagination.Item>;
      items.push(item);
    } else if ( (current + advanceFromCurrent) >= n && n >= current ) {
      const item = <Pagination.Item key={n} active={current === n} onClick={() => {update(n)}}>{n}</Pagination.Item>;
      items.push(item);
    } else if (items[items.length - 1].type.displayName !== 'Ellipsis'){
      // only displays an ellipsis if the last item in the array is not already an ellipsis
      const item = <Pagination.Ellipsis key={n}/>;
      items.push(item);
    }
  }

  return (
    items.map(el => el)
  );


};

export {
  getItems,
};