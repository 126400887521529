import axios from "axios";

/**
 * Creates a new axios instance for the backend
 * @returns {AxiosInstance}
 */
const axiosLaravelAPI = () => {
  let hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    hostname = "http://localhost:8080";
  } else if (hostname.includes("192.168.178.21")) {
    hostname = "http://192.168.178.21/mdb-backend";
  } else {
    hostname = "https://www.famserver.familyds.com/mdb-backend";
  }

  const axiosConfig = {
    baseURL: hostname + process.env.REACT_APP_API_PATH,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  };

  return axios.create(axiosConfig);
};

/**
 * Cross site reference request to the backend
 * @returns {Promise<AxiosResponse<any>>}
 */
const csrf = () => axiosLaravelAPI().get("/csrf-cookie");

/**
 * Request for the statistics data
 * @param page
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStatistics = (page) =>
  axiosLaravelAPI()
    .get("/statistic?page=" + page)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request for the user information
 * @param page
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUsersTable = (page) =>
  axiosLaravelAPI()
    .get("/user?page=" + page)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request for information about a single user
 * @param username
 * @returns {Promise<AxiosResponse<any>>}
 */
const singleUser = (username) =>
  axiosLaravelAPI()
    .get("/user/" + username)
    .then((res) => {
      if (res.status === 200) {
        return true;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to patch a user based on its username with provided data
 * @param username
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchUser = (username, data) =>
  axiosLaravelAPI()
    .patch("/user/" + username, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to change the password for user with the provided username
 * @param username
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const changeUserPassword = (username, data) =>
  axiosLaravelAPI()
    .post("/user/change_password/" + username, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to get the media data
 * @param page
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMediasTable = (page) =>
  axiosLaravelAPI()
    .get("/media/table?page=" + page)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to get a single media with specific id
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMedia = (id) =>
  axiosLaravelAPI()
    .get("/media/" + id)
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to patch a single media with specific id
 * @param id
 * @param {FormData} data
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchMedia = (id, data) =>
  axiosLaravelAPI()
    .post("/media/" + id, data)
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to mark media as seen
 * @param id
 * @returns {Promise<boolean>}
 */
const markMediaSeen = (id) =>
  axiosLaravelAPI()
    .post("/media/mark_seen/" + id)
    .then((res) => {
      return res.status === 200;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to mark media as unseen
 * @param id
 * @returns {Promise<boolean>}
 */
const markMediaUnseen = (id) =>
  axiosLaravelAPI()
    .post("/media/mark_unseen/" + id)
    .then((res) => {
      return res.status === 200;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Search only for titles of media
 * @param data JSON containing a title
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTitles = async (data) =>
  axiosLaravelAPI()
    .post("/media/titles", data)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return [];
    })
    .catch((error) => {
      throw error;
    });

/**
 * Search for media
 * @param data JSON that can contain a title. Mediums, genres and age_ratings as array
 * @returns {Promise<AxiosResponse<any>>}
 */
const searchMedia = (data) =>
  axiosLaravelAPI()
    .post("/media/search", data)
    .then((res) => {
      if (res.status === 200) {
        return res;
      }
      return null;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to get all mediums
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMediums = () =>
  axiosLaravelAPI()
    .get("/medium")
    .then((res) => {
      if (res.status === 200) {
        return res.data.map((medium) => medium.medium);
      }
      return [];
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to get all Genres
 * @returns {Promise<AxiosResponse<any>>}
 */
const getGenre = () =>
  axiosLaravelAPI()
    .get("/genre")
    .then((res) => {
      if (res.status === 200) {
        return res.data.map((genre) => genre.name);
      }
      return [];
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to get all age ratings
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFSK = () =>
  axiosLaravelAPI()
    .get("/fsk")
    .then((res) => {
      if (res.status === 200) {
        return res.data.map((fsk) => fsk.fsk);
      }
      return [];
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to get all roles
 * @returns {Promise<AxiosResponse<any>>}
 */
const getRoles = () =>
  axiosLaravelAPI()
    .get("/role")
    .then((res) => {
      if (res.status === 200) {
        return res.data.map((role) => role.role);
      }
      return [];
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to get all locations
 * @returns {Promise<AxiosResponse<any>>}
 */
const getLocations = () =>
  axiosLaravelAPI()
    .get("/location")
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to add new location
 * @returns {Promise<AxiosResponse<any>>}
 */
const addLocation = (data) =>
  axiosLaravelAPI()
    .put("/location", data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to edit a location
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchLocation = (id, data) =>
  axiosLaravelAPI()
    .patch("/location/" + id, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Delete request, for a type
 * @param type user, media, medium or location
 * @param id Id of the type that should be deleted
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteType = (type, id) =>
  axiosLaravelAPI()
    .delete(`/${type}/` + id)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request that returns all keywords
 * @returns {Promise<AxiosResponse<any>>}
 */
const getKeywords = (page) =>
  axiosLaravelAPI()
    .get("keyword?page=" + page)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to add new keyword
 * @param {*} data
 * @returns {Promise<AxiosResponse<any>>}
 */
const addKeyword = (data) =>
  axiosLaravelAPI()
    .put("/keyword", data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to search for keywords
 * @param {string} query
 * @param {number} page
 * @returns {Promise<AxiosResponse<any>>}
 */
const searchKeyword = (query, page = 1) =>
  axiosLaravelAPI()
    .post("/keyword/search?page=" + page, { keyword: query })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });

/**
 * Request to edit a keyword
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchKeyword = (keyword, data) =>
  axiosLaravelAPI()
    .patch("/keyword/" + keyword, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });

export {
  axiosLaravelAPI,
  csrf,
  getMediasTable,
  markMediaSeen,
  markMediaUnseen,
  getMedia,
  patchMedia,
  getTitles,
  searchMedia,
  getUsersTable,
  getStatistics,
  patchUser,
  changeUserPassword,
  getMediums,
  getGenre,
  getFSK,
  getRoles,
  getLocations,
  addLocation,
  patchLocation,
  deleteType,
  getKeywords,
  searchKeyword,
  addKeyword,
  patchKeyword,
};
