import React from 'react';
import { Container } from 'react-bootstrap';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tmdbActive } from '../utils/addUpdateMedia.js';

/**
 * Component that represents the footer of the site
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = (props) => {
  return (
    <Container id="mdb-footer" fluid>
      <div className="mdb-tmdb-logo pt-2">
        {tmdbActive() &&
        <React.Fragment>
          <p className="d-inline">This product uses the TMDb API but is not endorsed or certified by TMDb. </p>
          <a className="d-inline" href="https://www.themoviedb.org"><img width="40px"
                                                                         src={`${process.env.PUBLIC_URL}/tmdb-logo.svg`}
                                                                         alt="TMDB logo"/></a>
        </React.Fragment>
        }
      </div>
      <p className="mdb-copy-right pt-1"><FontAwesomeIcon icon={faCopyright}/> 2021</p>

    </Container>
  );
};

export default Footer;