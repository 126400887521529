import React from 'react';
import { Media } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * Component that displays a skeleton of the media component
 * @param noLink if no link is wrapped around set this parameter
 * @param full
 * @returns {JSX.Element}
 * @constructor
 */
const EmptyMedia = ({ noLink, full }) => {
  const { t, i18n } = useTranslation('media');
  const date = new Date().toLocaleDateString(i18n.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return (
    <Media className={`mdb-empty-media mdb-card-style ${noLink ? 'mdb-media-no-link' : ''} ${full ? 'mdb-media-full' : 'mdb-media'} mb-3 mt-3`}>
      <div
        className="mdb-media-poster mdb-no-media-poster ml-md-3 mr-md-3 ml-auto mr-auto mt-3 mb-lg-3"
      />
      <Media.Body className="p-3">
        <div className="d-flex justify-content-md-start justify-content-center">
          <p className={`mdb-fsk mr-3`}>0</p>
          <h4>{t('no-media')}</h4>
        </div>

        <p>{t('added') + date}</p>
        {!noLink && <p>{t('no-media-adding')}</p>}
        <p className="mdb-no-media-text"/>
        <p className="mdb-no-media-text"/>
        <p className="mdb-no-media-text"/>
      </Media.Body>
    </Media>
  );
};

export default EmptyMedia;