import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useError } from '../../contexts/ErrorContext.js';
import { axiosLaravelAPI, getGenre } from '../../apis/AxiosLaravel.js';
import {
  getKeyFromYoutubeLink,
  handleCheckBoxes,
  tmdbActive,
  validateData,
} from '../../utils/addUpdateMedia.js';
import DropzoneComponent from './DropzoneComponent.js';
import { useRouter } from '../../hooks/UseRouter.js';
import TMDBCastTypeAhead from './TMDBCastTypeAhead.js';
import { getPosterSRC } from '../../utils/media.js';
import SeasonTypeAhead from './SeasonTypeAhead.js';
import TMDBKeywordTypeAhead from './TMDBKeywordTypeAhead.js';

/**
 * Component that contains the manual add form for media
 * @param mediums
 * @returns {JSX.Element}
 * @constructor
 */
const ManualAdd = ({ mediums, laravelFSK, laravelLocations }) => {
  const { t } = useTranslation('media');
  const router = useRouter();
  const errorContext = useError();
  const [laravelGenre, setLaravelGenre] = useState([]);
  const [file, setFile] = useState(null);
  const [fileValidation, setFileValidation] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [validation, setValidation] = useState({
    type: null,
    title: null,
    release_date: null,
    overview: null,
    youtube_link: null,
    age_rating: null,
    location: null,
    mediums: null,
    genres: null,
    cast: null,
    seasons: null
  });
  const [data, setData] = useState({
    type: '',
    title: '',
    release_date: '',
    overview: '',
    youtube_link: '',
    age_rating: '0',
    location: '',
    mediums: [],
    genres: [],
    cast: [],
    seasons: []
  });

  const sendCreateRequest = (data) => {
    axiosLaravelAPI().post('/media', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        if (res.status === 201) {
          router.push('/media/' + res.data.id);
        }
      })
      .catch(error => {
        resetValidation();
        setDisabled(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log('ManualAdd');
          console.log(error);
        }
      });
  };

  /**
   * Effect that is executed on load of the component and loads genres and age ratings
   */
  useEffect(() => {
    getGenre().then(genres => {
      setLaravelGenre(genres);
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log('ManualAdd');
          console.log(error);
        }
      });
  }, []);

  const resetValidation = () => {
    setFileValidation(null);
    setValidation({
      type: null,
      title: null,
      release_date: null,
      overview: null,
      youtube_link: null,
      age_rating: null,
      location: null,
      mediums: null,
      genres: null,
      cast: null,
      seasons: null
    });
  };

  const handleChange = (e) => {
    resetValidation();
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleDroppedFile = (file) => {
    resetValidation();
    setFile(file);
  };

  const handleCheckbox = (e) => {
    resetValidation();
    handleCheckBoxes(e.target.name, e.target.value, data, setData);
  };

  const handleSeasons = (seasons) => {
    setData({
      ...data,
      seasons,
    });
  };

  /**
   * Gets all the movie information from tmdb and bundles them
   * into one object, then set the state
   * @param persons
   */
  const handlePersonSearchValue = persons => {
    resetValidation();
    setData({
      ...data,
      cast: persons,
    });
  };

  /**
   * Gets all the movie information from tmdb and bundles them
   * into one object, then set the state
   * @param keywords
   */
  const handleKeywordSearchValue = keywords => {
    resetValidation();
    setData({
      ...data,
      keywords: keywords,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    const error = validateData(data);

    const temp = {};
    Object.keys(validation).forEach((key) => {
      const toTest = error?.details?.find(err => err.path.includes(key));
      temp[key] = !toTest;
    });

    if (temp) {
      setValidation({
        ...validation,
        ...temp,
      });
    }

    if (!file) {
      setFileValidation(false);
    } else {
      setFileValidation(true);
    }

    if (!error && file) {
      const fd = new FormData();

      setData({
        ...data,
        youtube_link: getKeyFromYoutubeLink(data.youtube_link),
      });

      // Workaround to set the youtube link, because setData isn´t executed properly
      data.youtube_link = getKeyFromYoutubeLink(data.youtube_link);

      Object.keys(data).forEach(el => {

        if (el === 'mediums' || el === 'genres' || el === 'seasons') {
          data[el]?.forEach(arr => {
            fd.append(el + '[]', arr);
          });
        } else if (el === 'cast') {
          data[el]?.forEach(arr => {
            fd.append(el + '[]', JSON.stringify(arr));
          });
        } else {
          fd.append(el, data[el]);
        }
      });

      fd.append('poster_file', file);

      fd.append('_method', 'put');

      sendCreateRequest(fd);

    } else {
      setDisabled(false);
    }
  };

  return (
    <Form id="mdb-create-form" className="p-3" noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="mdb-create-form.formMediaType">
        <Form.Label>{t('add-media.select-label')}</Form.Label>
        <Form.Control
          name="type"
          value={data.type}
          onChange={handleChange}
          as="select"
          disabled={disabled}
          isInvalid={validation.type !== null ? !validation.type : null}
          isValid={validation.type}
        >
          <option/>
          <option value="Movie">{t('add-media.select-movie')}</option>
          <option value="TV">{t('add-media.select-tv')}</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {t('form-feedback.type')}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="mdb-create-form.formTitle">
        <Form.Label>{t('add-media.title-label', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}</Form.Label>
        <Form.Control
          name="title"
          value={data.title}
          onChange={handleChange}
          type="text"
          disabled={disabled}
          isInvalid={validation.title !== null ? !validation.title : null}
          isValid={validation.title}
        />
        <Form.Control.Feedback type="invalid">
          {t('form-feedback.title')}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="mdb-create-form.formOverview">
        <Form.Label>{t('add-media.overview-label', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}</Form.Label>
        <Form.Control
          name="overview"
          value={data.overview}
          onChange={handleChange}
          as="textarea"
          rows={3}
          disabled={disabled}
          isInvalid={validation.overview !== null ? !validation.overview : null}
          isValid={validation.overview}
        />
      </Form.Group>

      <Form.Group controlId="mdb-create-form.formFsk">
        <Form.Label>{t('add-media.fsk-label', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}</Form.Label>
        <Form.Control
          required
          name="age_rating"
          value={data.age_rating}
          onChange={handleChange}
          as="select"
          disabled={disabled}
          isInvalid={validation.age_rating !== null ? !validation.age_rating : null}
          isValid={validation.age_rating}
        >
          <option value=''/>
          {
            laravelFSK?.map((fsk) => (
              <option key={fsk} value={fsk}>{fsk}</option>
            ))
          }
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="mdb-create-form.formLocation">
        <Form.Label>{t('add-media.location-label', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}</Form.Label>
        <Form.Control
          required
          name="location"
          value={data.location}
          onChange={handleChange}
          as="select"
          disabled={disabled}
          isInvalid={validation.location !== null ? !validation.location : null}
          isValid={validation.location}
        >
          <option value=''/>
          {
            laravelLocations?.map((location) => (
              <option key={location} value={location}>{location}</option>
            ))
          }
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="mdb-create-form.formReleaseDate">
        <Form.Label>{t('add-media.release-date-label', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}</Form.Label>
        <Form.Control
          required
          name="release_date"
          value={data.release_date}
          onChange={handleChange}
          type="date"
          disabled={disabled}
          isInvalid={validation.release_date !== null ? !validation.release_date : null}
          isValid={validation.release_date}
        />
        <Form.Control.Feedback type="invalid">
          {t('form-feedback.release-date')}
        </Form.Control.Feedback>
      </Form.Group>

      {
        data?.type?.toLowerCase() === 'tv' &&
        <SeasonTypeAhead
          handleSelected={handleSeasons}
          isValid={validation.seasons}
          isInvalid={validation.seasons !== null ? !validation.seasons : null}/>
      }

      {tmdbActive() && <Form.Group controlId="formBasicTMDBSearch">
        <Form.Label>{t('add-media.search-for-actors-label')}</Form.Label>
        <TMDBCastTypeAhead handleSearchValue={handlePersonSearchValue}
                           resetValidation={resetValidation}
                           disabled={disabled}
                           placeholcer={t('add-media.actor-search-placeholder')}
                           isInvalid={validation.title !== null ? !validation.title : null}
                           isValid={validation.title}
        />
        <Form.Control.Feedback type="invalid"
                               style={{ display: `${validation.title !== null ? !validation.title ? 'block' : 'none' : 'none'}` }}>
          {t('form-feedback.title-select')}
        </Form.Control.Feedback>
      </Form.Group>}

      <Form.Group controlId="mdb-create-form.formReleaseDate">
        <Form.Label>{t('add-media.poster-label', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}</Form.Label>
        <div className="d-flex">
          {data?.poster_path && <Form.Text className="mdb-poster-preview mr-2"><img
            src={getPosterSRC(data?.tmdb_id, data?.poster_path, 'w92')} alt={data?.title + ' poster'}/></Form.Text>}
          <DropzoneComponent handleDroppedFile={handleDroppedFile} disabled={disabled} isValid={fileValidation}
                             isInvalid={fileValidation}/>
        </div>
      </Form.Group>

      <Form.Group controlId="mdb-create-form.formYoutubeLink">
        <Form.Label>
          {t('add-media.youtube-link-label', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}
        </Form.Label>
        <Form.Text>
          <a className="mdb-youtube-link-label"
             href={data?.title ? `https://www.youtube.com/results?search_query=${data.title}` : 'https://www.youtube.com'}
             rel="noreferrer"
             target="_blank">
            {t('add-media.youtube-search')}
          </a>
        </Form.Text>
        <Form.Control
          name="youtube_link"
          value={data.youtube_link}
          onChange={handleChange}
          pattern="https://((www)?(\.)?)youtube(\.)com/watch(\?)v=([a-zA-Z\d&=])+"
          type="url"
          disabled={disabled}
          isInvalid={validation.youtube_link !== null ? !validation.youtube_link : null}
          isValid={validation.youtube_link}
        />
        <Form.Control.Feedback type="invalid">
          {t('form-feedback.youtube-link')}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group required controlId="mdb-create-form.formMediums" className="mb-3">
        <Form.Label>{t('add-media.select-mediums-label')}</Form.Label>
        <div>
          {mediums?.map((medium) => (
            <Form.Check key={medium}
                        inline
                        label={medium}
                        name="mediums"
                        onChange={handleCheckbox}
                        type="checkbox"
                        value={medium}
                        id={`inline-checkbox-${medium.toLowerCase()}`}
                        disabled={disabled}
                        isInvalid={validation.mediums !== null ? !validation.mediums : null}
                        isValid={validation.mediums}
            />
          ))}
          <Form.Control.Feedback type="invalid"
                                 style={{ display: `${validation.mediums !== null ? !validation.mediums ? 'block' : 'none' : 'none'}` }}>
            {t('form-feedback.mediums')}
          </Form.Control.Feedback>
        </div>
      </Form.Group>

      <Form.Group required controlId="mdb-create-form.formGenres" className="mb-3">
        <Form.Label>{t('add-media.select-genre-label')}</Form.Label>
        <div className="mb-3">
          {laravelGenre?.map((genre) => (
            <Form.Check key={genre}
                        inline
                        label={t(`genre.${genre}`)}
                        name="genres"
                        onChange={handleCheckbox}
                        type="checkbox"
                        value={t(`genre.${genre}`)}
                        id={`inline-checkbox-${genre}`}
                        disabled={disabled}
                        isInvalid={validation.genres !== null ? !validation.genres : null}
                        isValid={validation.genres}
            />
          ))}
          <Form.Control.Feedback type="invalid"
                                 style={{ display: `${validation.genres !== null ? !validation.genres ? 'block' : 'none' : 'none'}` }}>
            {t('form-feedback.genres')}
          </Form.Control.Feedback>
        </div>
      </Form.Group>

      {tmdbActive() && <Form.Group controlId="formBasicTMDBSearch">
        <Form.Label>{t('add-media.search-for-keywords-label')}</Form.Label>
        <TMDBKeywordTypeAhead handleSearchValue={handleKeywordSearchValue}
                           resetValidation={resetValidation}
                           disabled={disabled}
                           placeholcer={t('add-media.keyword-search-placeholder')}
        />
      </Form.Group>}

      <Button className="mdb-add-media-form-submit mt-3" variant="primary" type="submit" disabled={disabled}>
        {t('add-media.submit-button', { type: data?.type === 'TV' ? t('add-media.select-tv') : data?.type === 'Movie' ? t('add-media.select-movie') : 'Media' })}
      </Button>
    </Form>
  );
};

export default ManualAdd;