import React, { useState } from 'react';
import { Button, Media } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

/**
 * Component that handles the display of the media cast
 * @param cast Array containing the cast objects
 * @returns {JSX.Element}
 * @constructor
 */
const CastComponent = ({ cast }) => {
  const { t } = useTranslation('media');
  const [display, setDisplay] = useState({ current: cast.slice(0, 6), count: 6, lastStep: 0 });

  const maxCount = cast.length;
  const steps = 6;

  /**
   * Method that handles the display of the next actors in steps
   * @param count
   */
  const handleNextDisplay = (count) => {
    if ((maxCount - count) >= steps) {
      setDisplay({
        ...display,
        current: cast.slice(0, count + steps),
        count: count + steps,
      });
    } else if ((maxCount - count) > 0) {
      setDisplay({
        current: cast.slice(0, count + (maxCount - count)),
        count: count + (maxCount - count),
        lastStep: (maxCount - count),
      });
    }
  };

  /**
   * Method that handles the hiding of displayed actors in steps
   * @param count
   * @param lastStep
   */
  const hideNextDisplay = (count, lastStep) => {
    if (count === maxCount) {
      setDisplay({
        ...display,
        current: cast.slice(0, count - lastStep),
        count: count - lastStep,
      });
    } else if (count > steps) {
      setDisplay({
        ...display,
        current: cast.slice(0, count - steps),
        count: count - steps,
      });
    }
  };

  /**
   * Hides all actors until the first 6
   */
  const hideUntilStep = () => {
    setDisplay({
      ...display,
      current: cast.slice(0, steps),
      count: steps,
      lastStep: 0,
    });
  };

  return (
    <React.Fragment>
      <div className="mdb-media-cast pb-3">
        {display.current.map(person => (
          <Media key={person.name} className="mt-2 mb-2">
            {person.profile_path &&
            <img className="mr-2" src={'https://www.themoviedb.org/t/p/w185' + person.profile_path} alt={person.name}/>}
            {!person.profile_path &&
            <div className="mr-2 mdb-media-no-profile-img"><FontAwesomeIcon icon={faUser}/></div>}
            <Media.Body>
              <h5>{person.name}</h5>
              {person.character && <p>{t('person-as') + person.character}</p>}
              {person.roles && person.roles?.length !== 0 && <p>{t('person-as') + person.roles?.join(', ')}</p>}
            </Media.Body>
          </Media>
        ))}

      </div>
      <div className="d-flex justify-content-center pb-5">
        {display.count !== maxCount && maxCount > steps && <Button className="mdb-cast-button" variant="primary"
                                               onClick={() => handleNextDisplay(display.count)}>{t('cast-show-next')}</Button>}
        {display.count !== steps &&
        <Button className={`mdb-cast-button ${display.count !== maxCount ? 'ml-2' : ''}`} variant="primary"
                onClick={() => hideNextDisplay(display.count, display.lastStep)}>{t('cast-hide-next')}</Button>}
        {display.count !== steps &&
        <Button className="mdb-cast-button ml-2" variant="primary"
                onClick={hideUntilStep}>{t('cast-hide-all', { steps })}
        </Button>
        }
      </div>
    </React.Fragment>
  );
};

export default CastComponent;