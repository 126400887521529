import React, { useState } from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import { useError } from '../contexts/ErrorContext.js';
import { useTranslation } from 'react-i18next';
import SearchFieldComponent from '../components/media/SearchFieldComponent.js';
import MediaComponent from '../components/media/MediaComponent.js';
import NoMediaComponent from '../components/media/NoMediaComponent.js';
import PaginationComponent from '../components/media/PaginationComponent.js';
import { useLocation } from 'react-router-dom';
import { searchMedia } from '../apis/AxiosLaravel.js';

/**
 * Component to search for media
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SearchMedia = (props) => {
  const [media, setMedia] = useState({ res: null, status: false });
  const [search, setSearch] = useState({ value: null, status: false });
  const { t } = useTranslation();
  const errorContext = useError();

  const location = useLocation().search;
  const params = new URLSearchParams(location);

  /**
   * Handles typeahead search value
   * @param query
   */
  const handleSearchValue = (query) => {
    setSearch({
      ...search,
      value: query,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch({
      ...search,
      status: true,
    });

    const searchData = {
      title: search.value,
    };

    searchMedia(searchData)
      .then(res => {
        setMedia({ res: res.data, status: true });
        setSearch({
          ...search,
          status: false,
        });
      })
      .catch(error => {
        setMedia({ res: null, status: true });
        setSearch({
          ...search,
          status: false,
        });
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  return (
    <React.Fragment>
      <Container id="mdb-media-search">
        {
          errorContext.alert()
        }
        <h2
          className="mdb-page-header mdb-card-style mt-3 p-2">{t('search.header')}</h2>
        <Container className="mdb-search mdb-card-style p-3">
          <Form id="mdb-search-form" noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicMediaSearch">
              <SearchFieldComponent handleSearchValue={handleSearchValue} handleSubmit={handleSubmit}/>
            </Form.Group>

            <Form.Group>
              <Button className="mdb-search-button" variant="outline-primary"
                      type="submit">{t('search.button')}</Button>
            </Form.Group>
          </Form>
        </Container>
        <h2 className="mdb-page-header mdb-card-style mt-3 p-2">{t('search.found.header')}</h2>
        <Container id="mdb-found-media">
          {
            search.status && !media.status && !errorContext.error.show &&
            <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
              <span className="sr-only">Loading...</span>
            </Spinner>
          }
          {
            media?.status && media?.res?.map(media => <MediaComponent key={media.id}
                                                                      media={media}/>)
          }
          {
            media?.status && media?.res?.length === 0 && <React.Fragment>
              <NoMediaComponent/>
              <NoMediaComponent/>
            </React.Fragment>
          }
        </Container>
        <PaginationComponent response={media.res} page={params.get('page')}/>
      </Container>
    </React.Fragment>
  );
};

export default SearchMedia;