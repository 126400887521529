import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { getTitles } from '../../apis/AxiosLaravel.js';
import { useError } from '../../contexts/ErrorContext.js';

/**
 * Component that wraps async typeahead to search for titles
 * @param handleSearchValue parent function to handle the search value
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SearchFieldComponent = ({ handleSearchValue, handleSubmit, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [ref, setRef] = useState(null);
  const errorContext = useError();

  /**
   * Handle the search for the titles
   * @param query
   */
  const handleSearch = (query) => {
    // Call parent function with array, that contains input value
    handleSearchValue(ref.getInput().value);
    setIsLoading(true);

    const searchData = {
      title: query,
    };

    getTitles(searchData)
      .then(data => {
        setElements(data);
        setIsLoading(false);
      })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else if (error.response.status === 401) {
          errorContext.setTMDB401Error();
        } else {
          errorContext.setUnexpectedError();
          console.log('SearchFieldComponent');
          console.log(error);
        }
      });
  };

  /**
   * Handle the selection of a title
   * @param selection
   */
  const handleSelect = (selection) => {
    if (selection.length > 0) {
      handleSearchValue(selection[0]);
    }
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      id="mdb-search-title-typeahead"
      filterBy={filterBy}
      isLoading={isLoading}
      onSearch={handleSearch}
      onChange={handleSelect}
      autoFocus={true}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleSubmit(event);
          ref.toggleMenu();
        }
      }}
      options={elements}
      minLength={1}
      ref={typeahead => setRef(typeahead)}
    />
  );
};

export default SearchFieldComponent;