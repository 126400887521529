import React, { useEffect, useState } from 'react';
import { Modal, Table, Spinner } from 'react-bootstrap';
import { useError } from '../contexts/ErrorContext.js';
import { useTranslation } from 'react-i18next';
import { getLocations } from '../apis/AxiosLaravel.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteModalComponent from './DeleteModalComponent.js';
import EditAddLocationModalComponent from './EditAddLocationModalComponent.js';

/**
 * Component that represents the delete modal of the site
 * @param type the type to be deleted
 * @param reloadMedia method that reloads media
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LocationModalComponent = ({ ...props }) => {
  const errorContext = useError();
  const { t } = useTranslation('location');
  const [locations, setLocations] = useState({ res: null });
  const [isLoading, setIsLoading] = useState(false);
  const [errorLocationFetch, setErrorLocationFetch] = useState(false);
  const [deleteShow, setDeleteShow] = useState({ show: false, location: '', id: '' });
  const [editShow, setEditShow] = useState({ show: false, type: null, location: null });

  /**
   * Load locations on load
   */
  useEffect(() => {
    loadLocations();
  }, []);

  /**
   * Method that loads the data for table
   */
  const loadLocations = () => {
    setIsLoading(true);
    getLocations()
      .then(res => {
        setIsLoading(false);
        setLocations({ res });
      })
      .catch(error => {
        setIsLoading(false)
        setErrorLocationFetch(true);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('modal.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isLoading &&  <Spinner id="mdb-modal-spinner" className="d-block mr-auto ml-auto mb-5 mt-5" animation="border" role="status" size="xl">
                              <span className="sr-only">Loading...</span>
                          </Spinner>
          }
          {!isLoading && <Table striped responsive>
            <thead className="text-center">
            <tr>
              <th>Id</th>
              <th>{t('table.name')}</th>
              <th>{t('media:table.edit-header')}</th>
              <th>{t('media:table.delete-header')}</th>
            </tr>
            </thead>
            <tbody>
            {
              locations?.res?.data?.map((el, index) => {

                return (
                  <tr key={index}>
                    <td>{el.id}</td>
                    <td className="mdb-table-location">{el.location}</td>
                    <td className="mdb-table-edit" aria-label={`Edit ${el.location}`}
                        onClick={() => setEditShow({ show: true, type: 'edit', location: el })}><FontAwesomeIcon
                      icon={faEdit}/>
                    </td>
                    <td className="mdb-table-delete" aria-label={`Delete ${el.location}`}
                        onClick={() => setDeleteShow({ show: true, location: el.location, id: el.id })}><FontAwesomeIcon
                      icon={faTrash}/></td>
                  </tr>
                );
              })
            }
            <tr>
              <td
                colSpan="12"
                aria-controls="button"
                className="mdb-table-add-new"
                onClick={() => setEditShow({...editShow, show: true, type: 'create' })}
              >
                <FontAwesomeIcon className="mr-2" icon={faPlusSquare}/>
                {t('modal.add-new')}
              </td>
            </tr>
            </tbody>
          </Table>}
        </Modal.Body>
      </Modal>
      <EditAddLocationModalComponent
        type={editShow.type}
        location={editShow.location}
        reloadMedia={loadLocations}
        show={editShow.show}
        onHide={() => setEditShow({
          show: false,
          type: null,
          location: null
        })}
      />
      <DeleteModalComponent
        type="location"
        title={deleteShow.location}
        param={deleteShow.id}
        reloadMedia={loadLocations}
        show={deleteShow.show}
        onHide={() => setDeleteShow({
          show: false,
          title: '',
          id: null,
        })}
      />
    </React.Fragment>

  );

};

export default LocationModalComponent;