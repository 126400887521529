import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext.js';
import { useError } from '../contexts/ErrorContext.js';
import Joi from 'joi';

/**
 * Validation schema for the login data
 * @type {Joi.ObjectSchema<any>}
 */
const schema = Joi.object({
  username: Joi.string().required(),
  password: Joi.string().required(),
});

/**
 * Modal component that contains the login
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LoginModalComponent = (props) => {
  const usernameInput = useRef(null);
  const auth = useAuth();
  const errorContext = useError();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({
    username: null,
    password: null,
  });
  const [formData, setFormData] = useState({ username: '', password: '' });
  const { t } = useTranslation('header');

  /**
   * Effect that is executed on first load and change of show variable in props
   */
  useEffect(() => {
    if (usernameInput.current) {
      usernameInput.current.focus();
    }
  }, [props.show]);

  const resetValidation = () => {
    setValidation({ username: null, password: null });
  };

  const resetFormData = () => {
    setFormData({ username: '', password: '' });
  };

  /**
   * Handle input changes
   * @param e event object
   */
  const handleChange = (e) => {
    e.preventDefault();
    resetValidation();

    setFormData(prevState => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleEnterKey = (e) => {
    if (e.charCode === 13) {
      handleLogin(e);
    }
  }

  /**
   * Method that has all necessary steps to send the login request
   * @param e event object
   */
  const handleLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    const { error } = schema.validate(formData, {
      abortEarly: false,
      errors: { label: 'key' },
    });

    const temp = {};
    Object.keys(validation).forEach((key) => {
      const toTest = error?.details?.find(err => err.path.includes(key));
      temp[key] = !toTest;
    });

    if (temp) {
      setValidation({
        ...validation,
        ...temp,
      });
    }

    if (!error) {
      setLoading(true);
      auth.login(formData.username, formData.password)
        .then(res => {
          if (res) {
            resetFormData();
            setDisabled(false);
            setLoading(false);
            props.onHide();
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            if (error.response.status === 401) {
              setValidation({username: false, password: false})
              setDisabled(false);
              setLoading(false);
            } else {
              errorContext.setNetworkError();
              resetFormData();
              setDisabled(false);
              setLoading(false);
              props.onHide();
            }
          } else {
            errorContext.setNetworkError();
            resetFormData();
            setDisabled(false);
            setLoading(false);
            props.onHide();
          }
        });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      onExiting={() => {
        resetValidation();
        resetFormData();
      }}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('login.header')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          loading &&
          <Spinner id="mdb-modal-spinner" className="d-block mr-auto ml-auto mb-5 mt-5" animation="border" role="status" size="xl">
            <span className="sr-only">Loading...</span>
          </Spinner>
        }
        {
          !loading &&
          <Form id="mdb-login-form" noValidate>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>{t('login.username')}</Form.Label>
              <Form.Control required
                            type="text"
                            name="username"
                            ref={usernameInput}
                            onChange={handleChange}
                            value={formData.username}
                            disabled={disabled}
                            placeholder={t('login.username')}
                            isInvalid={validation.username !== null ? !validation.username : null}
                            isValid={validation.username}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>{t('login.password')}</Form.Label>
              <Form.Control required
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={formData.password}
                            disabled={disabled}
                            placeholder={t('login.password')}
                            onKeyPress={handleEnterKey}
                            isInvalid={validation.password !== null ? !validation.password : null}
                            isValid={validation.password}
              />
              <Form.Control.Feedback type="invalid">
                {t('login.feedback')}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        }

      </Modal.Body>
        <Modal.Footer>
          { !loading && <Button type="submit" variant="outline-primary" onClick={handleLogin}>{t('login.button')}</Button> }
        <Button variant="outline-danger"
                onClick={() => {
                  resetFormData();
                  props.onHide();
                }}
        >{t('login.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModalComponent;