import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useError } from '../contexts/ErrorContext.js';
import { axiosLaravelAPI } from '../apis/AxiosLaravel.js';
import { Container, Spinner } from 'react-bootstrap';
import MediaComponent from '../components/media/MediaComponent.js';
import NoMediaComponent from '../components/media/NoMediaComponent.js';
import PaginationComponent from '../components/media/PaginationComponent.js';

/**
 * Component that shows media based on a provided type
 * @param type news or a-z
 * @returns {JSX.Element}
 * @constructor
 */
const ShowAllMedia = ({ type }) => {
  const { t } = useTranslation();
  const errorContext = useError();
  const [media, setMedia] = useState({ res: null, status: false });
  const [errorMediaFetch, setErrorMediaFetch] = useState(false);
  const history = useHistory();

  const location = useLocation().search;
  const params = new URLSearchParams(location);

  /**
   * Method to update parameters of the request
   * @param pageNumber
   * @param perPageNumber
   */
  const updateParams = (pageNumber, perPageNumber) => {

    if (pageNumber) {
      params.set('page', pageNumber);
    }

    if (perPageNumber) {
      params.set('per_page', perPageNumber);
    }

    history.push({ search: params.toString() });
  };

  let title;

  switch (type) {
    case 'a-z':
      title = t('a-z-page-header');
      break;
    default:
      title = t('news-page-header');
      break;
  }

  /**
   * Effect that is executed on type or url history change.
   * Reload media and sets url for the type.
   */
  useEffect(() => {
    let laravelURL;
    switch (type) {
      case 'a-z':
        const urlParams = params.toString ? '?' + params.toString() : '';
        laravelURL = '/media' + urlParams;
        break;
      default:
        laravelURL = '/media/news';
        break;
    }

    setMedia({
      ...media,
      status: false,
    });
    axiosLaravelAPI().get(laravelURL)
      .then(res => {
        if (res.status === 200) {
          if (res.data?.last_page < params.get('page') || params.get('page') < 1) {
            updateParams(1);
          }
          setMedia({ res: res.data, status: true });
        }
      })
      .catch(error => {
        setMedia({
          ...media,
          status: true
        })
        setErrorMediaFetch(true);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });

  }, [type, history.location]);

  return (
    <React.Fragment>
      <Container id="mdb-media-news">
        <h2 className="mdb-page-header mdb-card-style mt-3 p-2">{title}</h2>
        {
          errorContext.alert()
        }
        {
          !media.status && !errorMediaFetch && !errorContext.error.show &&
          <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
            <span className="sr-only">Loading...</span>
          </Spinner>
        }
        {
          Array.isArray(media?.res) && media?.status && media?.res?.map(media => <MediaComponent key={media.id}
                                                                                                 media={media}/>)
        }
        {
          media?.status && media?.res?.data?.map(media => <MediaComponent key={media.id} media={media}/>)
        }
        {
          media?.status && (media?.res?.length === 0 || media?.res?.data?.length === 0) && <React.Fragment>
            <NoMediaComponent/>
            <NoMediaComponent/>
          </React.Fragment>
        }
      </Container>
      <Container>
        <PaginationComponent response={media.res} page={params.get('page')} update={updateParams}/>
      </Container>
    </React.Fragment>
  );
};

export default ShowAllMedia;