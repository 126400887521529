import React, { useState, createContext, useEffect, useContext } from 'react';
import { axiosLaravelAPI, csrf } from '../apis/AxiosLaravel.js';

export const AuthContext = createContext([]);

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  const [user, setUser] = useState(null);

  const login = async (username, password) => {
    try {
      await csrf();
      const res = await axiosLaravelAPI().post('/login', { username, password });

      if (res.status === 200) {
        setUser(res.data);
        return true;
      }

    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    csrf()
      .then(() => {
        axiosLaravelAPI().post('/logout').then(res => {
          if (res.status === 200) {
            setUser(false);
          } else {
            setUser(false);
          }
        });
      })
      .catch(error => {
        setUser(false);
        throw error;
      });
  };

  const whoami = () => {
    axiosLaravelAPI().get('/user/whoami')
      .then(res => {
        if (res.status === 200) {
          setUser(res.data);
        }
      })
      .catch(error => {
        throw error;
      });
  };

  const changeOwnPassword = (data) => axiosLaravelAPI().post('/user/change_password', data)
      .then(res => {
        return true;
      })
    .catch(error => {throw error});


  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = axiosLaravelAPI().get('/check')
      .then((res) => {
        if (res.status === 204) {
          whoami();
        } else {
          setUser(false);
        }
      })
      .catch(error => {
        setUser(false);
      });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    login,
    logout,
    whoami,
    changeOwnPassword
  };
};