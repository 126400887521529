import { Button, Dropdown, Nav, NavDropdown, NavLink } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext.js'

/**
 * Navigation dropdown component that is shown for logged in users
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LoggedInUserNav = (props) => {
  const auth = useAuth();
  const { t } = useTranslation('header');

  return (
    <Dropdown as="li">
      <Dropdown.Toggle aria-label="Toggle Dropdown"  as={NavLink}><FontAwesomeIcon icon={faUser}/></Dropdown.Toggle>
      <Dropdown.Menu align={{ sm: 'right' }} as="ul">
        <Nav.Item as="li">
          <LinkContainer to="/profile">
            <NavDropdown.Item>{t('profile-link')}</NavDropdown.Item>
          </LinkContainer>
        </Nav.Item>
        {(auth?.user?.role === 'Creator' || auth?.user?.role === 'Admin') &&
        <React.Fragment>
          <Nav.Item as="li">
            <LinkContainer to="/media-management">
              <NavDropdown.Item>{t('media-management-link')}</NavDropdown.Item>
            </LinkContainer>
          </Nav.Item>
        </React.Fragment>
        }
        {auth?.user?.role === 'Admin' &&
          <React.Fragment>
            <Nav.Item as="li">
              <LinkContainer to="/user-management">
                <NavDropdown.Item>{t('user-management-link')}</NavDropdown.Item>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item as="li">
              <LinkContainer to="/statistic">
                <NavDropdown.Item>{t('statistic')}</NavDropdown.Item>
              </LinkContainer>
            </Nav.Item>
          </React.Fragment>
        }
        <Nav.Item as="li">
          <Button
            id="mdb-nav-button"
            aria-label="Logout"
            onClick={auth.logout}
          ><FontAwesomeIcon icon={faSignOutAlt}/></Button>
        </Nav.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LoggedInUserNav;