import axios from "axios";
import { castFilter, genreConverter } from "../utils/addUpdateMedia.js";

/**
 * Axios instance for tmdb requests
 * @returns {AxiosInstance}
 */
const axiosTMDBAPI = () => {
  const api = axios.create({
    baseURL: "https://api.themoviedb.org/3",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + process.env.REACT_APP_TMDB_API_KEY,
    },
  });

  // api.interceptors.request.use((config) => {
  //   config.params = {
  //     // the api_key needs to be supplied on every request
  //     api_key: process.env.REACT_APP_TMDB_API_KEY,

  //     // spread the request's params
  //     ...config.params,
  //   };
  //   return config;
  // });

  return api;
};

/**
 * Get tmdb media based on the id
 * @param type type of search, movie or tv
 * @param media media object with tmdb_id
 * @returns {Promise<unknown>} return populated media object
 */
const idSearch = (type, media) => {
  return new Promise((resolve, reject) => {
    type = type.toLowerCase();
    let params = { language: "de-DE" };
    if (type === "movie") {
      params.append_to_response = "release_dates,videos,casts,keywords";
    } else if (type === "tv") {
      params.append_to_response =
        "content_ratings,videos,aggregate_credits,keywords";
    }

    axiosTMDBAPI()
      .get(`/${type}/${media?.tmdb_id}`, {
        params,
      })
      .then((res) => {
        if (res.status === 200) {
          if (type === "movie") {
            const release_date_de = res.data.release_dates?.results?.find(
              (el) => el.iso_3166_1 === "DE"
            );

            if (release_date_de) {
              media.age_rating =
                release_date_de.release_dates[0]?.certification;
            }

            media.cast = castFilter(type, res.data.casts?.cast);

            media.keywords = res.data.keywords?.keywords.map((keyword) => {
              return keyword?.name;
            });
          } else if (type === "tv") {
            media.age_rating = res.data?.content_ratings?.results?.find(
              (el) => el.iso_3166_1 === "DE"
            )?.rating;

            if (!media.age_rating) {
              media.age_rating = "0";
            }

            media.cast = castFilter(type, res.data.aggregate_credits?.cast);

            media.keywords = res.data.keywords?.results.map((keyword) => {
              return keyword?.name;
            });
          }

          if (res.data.videos?.results?.length !== 0) {
            media.youtube_link =
              "https://www.youtube.com/watch?v=" +
              res.data.videos.results[0].key;
          } else {
            media.youtube_link = "";
          }

          media.genres = genreConverter(res.data.genres);

          resolve(media);
        }
      })
      .catch((error) => reject(error));
  });
};

/**
 * Get tmdb media by searching with a query
 * @param type type of search (movie, tv or person)
 * @param query
 * @param page
 * @returns {Promise<unknown>} return populated array as result
 */
const querySearch = (type, query, page = 1) => {
  return new Promise((resolve, reject) => {
    type = type.toLowerCase();
    axiosTMDBAPI()
      .get(`/search/${type}`, {
        params: { query, page, language: "de", region: "de" },
      })
      .then((res) => {
        if (res.status === 200) {
          let results = res.data?.results;
          if (type === "movie") {
            results = res.data.results.map((result) => {
              const { id, poster_path, overview, release_date, title } = result;

              return {
                tmdb_id: id,
                poster_path,
                overview,
                release_date,
                title,
              };
            });
          } else if (type === "tv") {
            results = res.data.results.map((result) => {
              let { id, poster_path, overview, first_air_date, name } = result;

              return {
                tmdb_id: id,
                poster_path,
                overview,
                release_date: first_air_date,
                title: name,
              };
            });
          } else if (type === "person") {
            results = res.data.results.map((result) => {
              const { id, profile_path, name } = result;

              return { id, profile_path, name };
            });
          } else if (type === "keywords") {
            results = res.data.results.map((result) => {
              const { name } = result;

              return name;
            });
          }
          resolve({
            elements: results,
            total_results: res.data?.total_results,
          });
        }
      })
      .catch((error) => reject(error));
  });
};

export { axiosTMDBAPI, idSearch, querySearch };
