import React, { useCallback, useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { Media } from "react-bootstrap";
import { useError } from "../../contexts/ErrorContext.js";
import { querySearch } from "../../apis/AxiosTMDB.js";

const CACHE = {};
const PER_PAGE = 20;

/**
 * Wrapper for the typeahead boostrap package for title selection
 * @param type
 * @param preSelected
 * @param disabled
 * @param handleSearchValue
 * @param placeholcer
 * @param resetValidation
 * @param isValid
 * @param isInvalid
 * @returns {JSX.Element}
 * @constructor
 */
const TMDBTitleTypeAhead = ({
  type,
  preSelected,
  disabled = false,
  handleSearchValue,
  placeholcer = "Search ...",
  resetValidation,
  isValid,
  isInvalid,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [searchType, setSearchType] = useState("movie");
  const [query, setQuery] = useState("");
  const { t } = useTranslation("media");
  const errorContext = useError();

  const handleSelect = (selected) => {
    if (selected.length !== 0) {
      handleSearchValue(selected);
    }
  };

  useEffect(() => {
    setSearchType(type.toLowerCase());
  }, [type]);

  const handleInputChange = (q) => {
    setQuery(q);
    handleSearch(q);
  };

  const handlePagination = (e, shownResults) => {
    const cachedQuery = CACHE[query];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.elements.length > shownResults ||
      cachedQuery.elements.length === cachedQuery.total_results
    ) {
      return;
    }

    setIsLoading(true);

    const page = cachedQuery.page + 1;

    querySearch(searchType, query, page)
      .then((res) => {
        const elements = cachedQuery.elements.concat(res.elements);
        CACHE[query] = { ...cachedQuery, elements, page };
        setElements(elements);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else if (error.response.status === 401) {
          errorContext.setTMDB401Error();
        } else {
          errorContext.setUnexpectedError();
          console.log("TMDBTitleTypeAhead");
          console.log(error);
        }
      });
  };

  const handleSearch = (q) => {
    if (CACHE[q]) {
      setElements(CACHE[q].elements);
      return;
    }

    resetValidation();
    setIsLoading(true);

    querySearch(searchType, q)
      .then((res) => {
        CACHE[q] = { ...res, page: 1 };
        setElements(res.elements);
        setIsLoading(false);
      })
      .catch((error) => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else if (error.response.status === 401) {
          errorContext.setTMDB401Error();
        } else {
          errorContext.setUnexpectedError();
          console.log("TMDBTitleTypeAhead");
          console.log(error);
        }
      });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id="mdb-tmdb-typeahead"
      className="mdb-tmdb-typeahead"
      isLoading={isLoading}
      labelKey={"title"}
      maxHeight={"390px"}
      maxResults={PER_PAGE - 1}
      flip
      defaultSelected={preSelected}
      disabled={disabled}
      minLength={2}
      onInputChange={handleInputChange}
      onPaginate={handlePagination}
      onSearch={handleSearch}
      onChange={handleSelect}
      options={elements}
      paginate
      placeholder={placeholcer}
      isInvalid={isInvalid}
      isValid={isValid}
      renderMenuItemChildren={(element) => (
        <Media>
          {element.poster_path && (
            <img
              alt={element.title + t("poster-alt")}
              src={"https://www.themoviedb.org/t/p/w92" + element.poster_path}
              style={{
                height: "auto",
                marginRight: "10px",
                width: "92px",
              }}
            />
          )}
          <Media.Body>
            <h2>{element.title ? element.title : element.name}</h2>
            {element.overview && (
              <p
                style={{
                  whiteSpace: "normal",
                  fontSize: "1rem",
                }}
              >
                {element.overview}
              </p>
            )}
          </Media.Body>
        </Media>
      )}
      useCache={false}
    />
  );
};

export default TMDBTitleTypeAhead;
