import React, { useCallback, useState } from 'react';
import { querySearch } from '../../apis/AxiosTMDB.js';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { Media } from 'react-bootstrap';
import { useError } from '../../contexts/ErrorContext.js';

const CACHE = {};
const PER_PAGE = 20;

/**
 * Wrapper for the typeahead boostrap package for cast selection
 * @param preSelected
 * @param disabled
 * @param handleSearchValue
 * @param placeholcer
 * @param resetValidation
 * @param isValid
 * @param isInvalid
 * @returns {JSX.Element}
 * @constructor
 */
const TMDBCastTypeAhead = ({
                         preSelected,
                         disabled = false,
                         handleSearchValue,
                         placeholcer = 'Search ...',
                         resetValidation,
                         isValid,
                         isInvalid,
                       }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [query, setQuery] = useState('');
  const { t } = useTranslation('media');
  const errorContext = useError();

  const handleSelect = (selected) => {
    if (selected.length !== 0) {
      handleSearchValue(selected);
    }
  };

  const handleInputChange = (q) => {
    setQuery(q);
  };

  const handlePagination = (e, shownResults) => {
    const cachedQuery = CACHE[query];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.elements.length > shownResults ||
      cachedQuery.elements.length === cachedQuery.total_results
    ) {
      return;
    }

    setIsLoading(true);

    const page = cachedQuery.page + 1;

    querySearch('person', query, page)
    .then(res => {
      const elements = cachedQuery.elements.concat(res.elements);
      CACHE[query] = { ...cachedQuery, elements, page };
      setElements(elements);
      setIsLoading(false);
    })
    .catch(error => {
      if (!error.response) {
        errorContext.setNetworkError();
      } else if (error.response.status === 401) {
        errorContext.setTMDB401Error();
      } else {
        errorContext.setUnexpectedError();
        console.log('TMDBTitleTypeAhead');
        console.log(error);
      }
    });
  };

  const handleSearch = useCallback((q) => {
    if (CACHE[q]) {
      setElements(CACHE[q].elements);
      return;
    }

    resetValidation();
    setIsLoading(true);

    
    querySearch('person', q)
      .then(res => {
        CACHE[q] = { ...res, page: 1 };
        setElements(res.elements);
        setIsLoading(false);
      })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else if (error.response.status === 401) {
          errorContext.setTMDB401Error();
        } else {
          errorContext.setUnexpectedError();
          console.log('TMDBTitleTypeAhead');
          console.log(error);
        }
      });
  }, []);

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id="mdb-tmdb-typeahead"
      className="mdb-tmdb-typeahead"
      isLoading={isLoading}
      multiple
      labelKey={'name'}
      maxHeight={'400px'}
      maxResults={5}
      flip
      defaultSelected={preSelected}
      disabled={disabled}
      paginate
      minLength={3}
      onInputChange={handleInputChange}
      onSearch={handleSearch}
      onPaginate={handlePagination}
      onChange={handleSelect}
      options={elements}
      placeholder={placeholcer}
      isInvalid={isInvalid}
      isValid={isValid}
      renderMenuItemChildren={(elements, props) => (
        <Media>
          {elements.poster_path && <img
            alt={elements.title + t('poster-alt')}
            src={'https://www.themoviedb.org/t/p/w92' + elements.poster_path}
            style={{
              height: 'auto',
              marginRight: '10px',
              width: '92px',
            }}
          />}

          {elements.profile_path && <img
            alt={elements.name + t('poster-alt')}
            src={'https://www.themoviedb.org/t/p/w92' + elements.profile_path}
            style={{
              height: 'auto',
              marginRight: '10px',
              width: '92px',
            }}
          />}
          <Media.Body>
            <h2>{elements.title ? elements.title : elements.name}</h2>
            {elements.overview && <p
              style={{
                whiteSpace: 'normal',
                fontSize: '1rem',
              }}
            >{elements.overview}</p>}
          </Media.Body>
        </Media>
      )}
    />
  );
};

export default TMDBCastTypeAhead;