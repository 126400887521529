import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useError } from '../contexts/ErrorContext.js';
import {
  axiosLaravelAPI,
  getRoles,
  patchUser,
  changeUserPassword,
} from '../apis/AxiosLaravel.js';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import Joi from 'joi';

/**
 * Validation schema for user creation data
 * @type {Joi.ObjectSchema<any>}
 */
const createUserScheme = Joi.object({
  username: Joi.string().required(),
  role: Joi.string().pattern(/(Admin|Creator|User)/).required(),
  password: Joi.string().min(8).required(),
  password_confirmation: Joi.string().equal(Joi.ref('password')).required(),
});

/**
 * Validation schema for user update data
 * @type {Joi.ObjectSchema<any>}
 */
const updateUserScheme = Joi.object({
  username: Joi.string().required(),
  role: Joi.string().pattern(/(Admin|Creator|User)/).required(),
  password: Joi.string().min(8).optional(),
  password_confirmation: Joi.string().when('password', {
    is: Joi.exist(),
    then: Joi.equal(Joi.ref('password')).required(),
    otherwise: Joi.optional(),
  }),
});

/**
 * Modal component that contains the create or update form for the user
 * @param type edit or update
 * @param userData data of user that should be updated
 * @param reloadMedia function that reloads the data on successful creation or update
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EditAddUserModalComponent = ({ type = 'edit', userData = null, reloadUsers, ...props }) => {
  const { t } = useTranslation();
  const errorContext = useError();
  const [modalError, setModalError] = useState(false);
  const [laravelRoles, setLaravelRoles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [validation, setValidation] = useState({
    username: null,
    password: null,
    password_confirmation: null,
    role: null,
  });
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    password_confirmation: '',
    role: 'User',
  });

  /**
   * Effect that loads all roles, is only executed on load of component
   */
  useEffect(() => {
    getRoles().then(roles => {
      setLaravelRoles(roles);
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });

  }, []);

  /**
   * Effect that sets the user data, is executed on userData change
   */
  useEffect(() => {
    if (userData) {
      const {id, ...rest} = userData;
      setFormData({
        ...formData,
        ...rest,
      });
    }
  }, [userData]);

  /**
   * Method that sends an update request
   * @param data
   */
  const sendUpdateRequest = (data) => {
    patchUser(userData.username, data)
      .then(res => {
        if (res.status === 200) {
          setDisabled(false);
          console.log(data);
          if (data.password) {
            setPassword(data);
          }
          resetData();
          resetValidation();
          reloadUsers();
          props.onHide();
        }
      })
      .catch(error => {
        resetValidation();
        setDisabled(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  /**
   * Method that sends a create request
   * @param data
   */
  const sendCreateRequest = (data) => {
    axiosLaravelAPI().put('/user', data)
      .then(res => {
        if (res.status === 201) {
          setDisabled(false);
          resetData();
          resetValidation();
          reloadUsers();
          props.onHide();
        }
      })
      .catch(error => {
        resetValidation();
        setDisabled(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  /**
   * Method that sends a password change request
   * @param data
   */
  const setPassword = (data) => {
    changeUserPassword(userData.username, data)
      .then(res => {
        if (res.status === 201) {
          setDisabled(false);
          resetData();
          resetValidation();
          reloadUsers();
          props.onHide();
        }
      })
      .catch(error => {
        resetValidation();
        setDisabled(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  }

  const resetData = () => {
    setFormData({
      username: '',
      password: '',
      password_confirmation: '',
      role: 'User',
    });
  };

  const resetValidation = () => {
    setValidation({
      username: null,
      password: null,
      password_confirmation: null,
      role: null,
    });
  };

  /**
   * Handles input changes
   * @param e event object
   */
  const handleChange = e => {
    resetValidation();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  /**
   * Handles form submits and data validation and calls the update or create send request methods
   */
  const handleSubmit = () => {
    setDisabled(true);
    let errors;

    if (type === 'create') {
      const { error, value } = createUserScheme.validate(formData);
      errors = error;
    } else if (type === 'edit') {
      const { error, value } = updateUserScheme.validate(formData);
      errors = error;
    }

    const temp = {};
    Object.keys(validation).forEach((key) => {
      const toTest = errors?.details?.find(err => err.path.includes(key));
      temp[key] = !toTest;
    });

    if (temp) {
      setValidation({
        ...validation,
        ...temp,
      });
    }

    if (!errors) {
      if (userData) {
        sendUpdateRequest(formData);
      } else {
        sendCreateRequest(formData);
      }
    } else {
      console.log(errors);
      setModalError(true);
      setDisabled(false);
    }
  };

  const hideError = () => {
    setModalError(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="true"
      centered
      onExited={() => {
        setModalError(false);
        resetValidation();
        resetData();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {userData ? t('edit-add-user-modal.edit-title', { username: userData?.username }) : t('edit-add-user-modal.create-title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          modalError &&
          <Alert variant="danger" onClick={hideError} dismissible>{t('edit-add-user-modal.error')}</Alert>
        }
        <Form id="mdb-edit-add-user-form" className="p-3">
          <Form.Group controlId="formBasicUsername">
            <Form.Label>{t('edit-add-user-modal.username-label')}</Form.Label>
            <Form.Control
              required
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              disabled={disabled}
              isInvalid={validation.username !== null ? !validation.username : null}
              isValid={validation.username}
            />
          </Form.Group>
          <Form.Group controlId="formBasicRole">
            <Form.Label>{t('edit-add-user-modal.role-label')}</Form.Label>
            <Form.Control
              required
              name="role"
              value={formData.role}
              onChange={handleChange}
              as="select"
              disabled={disabled}
              isInvalid={validation.role !== null ? !validation.role : null}
              isValid={validation.role}
            >
              {
                laravelRoles?.map((role) => (
                  <option key={role} value={role}>{role}</option>
                ))
              }
            </Form.Control>
          </Form.Group>
          <React.Fragment>
            <Form.Group controlId="formBasicNewPassword">
              <Form.Label>{t('edit-add-user-modal.password-label')}</Form.Label>
              <Form.Control required
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={formData.password}
                            disabled={disabled}
                            placeholder={t('edit-add-user-modal.password-placeholder')}
                            isInvalid={validation.password !== null ? !validation.password : null}
                            isValid={validation.password}
              />
              <Form.Control.Feedback type="invalid">
                {t('edit-add-user-modal.password-feedback')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formBasicNewPasswordConfirm">
              <Form.Label>{t('edit-add-user-modal.password-confirm-label')}</Form.Label>
              <Form.Control required
                            type="password"
                            name="password_confirmation"
                            onChange={handleChange}
                            value={formData.password_confirmation}
                            disabled={disabled}
                            placeholder={t('edit-add-user-modal.password-confirm-placeholder')}
                            isInvalid={validation.password_confirmation !== null ? !validation.password_confirmation : null}
                            isValid={validation.password_confirmation}
              />
              <Form.Control.Feedback type="invalid">
                {t('edit-add-user-modal.password-comfirmed-feedback')}
              </Form.Control.Feedback>
            </Form.Group>
          </React.Fragment>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="outline-primary"
                onClick={handleSubmit}>{userData ? t('edit-add-user-modal.edit-button') : t('edit-add-user-modal.create-button')}</Button>
        <Button variant="outline-danger"
                onClick={() => {
                  props.onHide();
                }}
        >{t('edit-add-user-modal.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAddUserModalComponent;