import React from 'react';
import { Button, Container, Media } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { tmdbActive } from '../../utils/addUpdateMedia.js';
import CastComponent from './CastComponent.js';
import { genreDisplay, getFSKClass, getPosterSRC } from '../../utils/media.js';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../contexts/AuthContext.js';
import { markMediaSeen, markMediaUnseen } from '../../apis/AxiosLaravel.js';
import { useError } from '../../contexts/ErrorContext.js';

/**
 * Component that displays the media data
 * @param media media data that should be displayed
 * @param full flag to display all media data and not only partial
 * @returns {JSX.Element}
 * @constructor
 */
const MediaComponent = ({ media, full }) => {
  const { t, i18n } = useTranslation('media');
  const fskClass = getFSKClass(media.age_rating);
  const imgSrc = getPosterSRC(media.tmdb_id, media.poster_path);
  const errorContext = useError();
  const authContext = useAuth();
  let youtubeLink;

  if (media.youtube_link) {
    youtubeLink = 'https://www.youtube-nocookie.com/embed/' + media.youtube_link;
  }

  // Format date based on language
  const added = new Date(media.created_at).toLocaleDateString(i18n.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const released = new Date(media.release_date).toLocaleDateString(i18n.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  /**
   * Method to send the media seen request
   * @param id
   */
  const handleMarkSeen = (id) => {
    markMediaSeen(id)
      .then(bool => bool ? authContext.whoami() : errorContext.setUnexpectedError())
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            errorContext.set401Error();
          } else {
            errorContext.setNetworkError();
          }
        }
      });
  };

  /**
   * Method to send the media unseen request
   * @param id
   */
  const handleMarkUnseen = (id) => {
    markMediaUnseen(id)
      .then(bool => bool ? authContext.whoami() : errorContext.setUnexpectedError())
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 401) {
            errorContext.set401Error();
          } else {
            errorContext.setNetworkError();
          }
        }
      });
  };

  return (
    <React.Fragment>
      {!full && <Link className="mdb-media-link" to={'/media/' + media.id}>
        <Media className="mdb-media mdb-card-style mb-3 mt-3">
          <img
            className="mdb-media-poster ml-md-3 mr-md-3 ml-auto mr-auto mt-3 mb-lg-3"
            src={imgSrc}
            alt={media.title + t('poster-alt')}
          />
          <Media.Body className="p-3">
            <div className="d-flex flex-md-row flex-column justify-content-md-between">
              <div className="d-flex justify-content-md-start justify-content-center">
                <p className={`mdb-fsk ${fskClass} mr-3`}>{media.age_rating}</p>
                <h4>{media.title}</h4>
              </div>

              {authContext?.user &&
              <div className="mdb-see-counter d-flex flex-column justify-content-center text-center">
                <p className="mb-1">{t('see-count')}</p>
                <div className="d-flex justify-content-center">
                  <Button title={t('mark-unseen')} className="mdb-mark-seen"
                          disabled={authContext?.user?.seen_media[media.id] <= 0}
                          variant="outline-primary" onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleMarkUnseen(media.id);
                  }}><FontAwesomeIcon icon={faMinus}/></Button>
                  <p className="mdb-see-count">{authContext?.user?.seen_media[media.id] ? authContext?.user?.seen_media[media.id] : 0}</p>
                  <Button title={t('mark-seen')} className="mdb-mark-unseen"
                          variant="outline-primary" onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleMarkSeen(media.id);
                  }}><FontAwesomeIcon icon={faPlus}/></Button>
                </div>
              </div>
              }


            </div>

            <p>{t('added') + added}</p>
            <p>{media.overview}</p>
          </Media.Body>
        </Media>
      </Link>
      }
      {
        full && <div className="mdb-media-full mdb-media mdb-card-style mdb-media-no-link mb-3 mt-3">
          <div className="mdb-media-full-head p-3">
            <img
              className="mdb-media-poster ml-md-3 mr-md-3 ml-auto mr-auto mb-3"
              src={imgSrc}
              alt={media.title + t('poster-alt')}
            />
            <div className="mdb-media-info ml-3 w-100">
              <h6 className="d-inline">{t('type')}</h6>
              <p className="d-inline">{t(media.type)}</p>
              <p/>
              <div className={`mdb-fsk mdb-full-fsk ${fskClass} mr-lg-3`}>{media.age_rating}</div>
              <h6 className="d-inline">{t('added')}</h6>
              <p className="d-inline">{added}</p>
              <p/>
              <h6 className="d-inline">{t('release-date')}</h6>
              <p className="d-inline">{released}</p>
              <p/>
              <h6 className="d-inline">{t('mediums')}</h6>
              <p className="d-inline">{media.mediums.join(', ')}</p>
              <p/>
              <h6 className="d-inline">{t('location')}</h6>
              <p className="d-inline">{media.location}</p>
              <p/>
              <h6 className="d-inline">{t('genres')}</h6>
              <p className="d-inline">{genreDisplay(media.genres, t)}</p>
              <p/>
              {media.keywords &&
              <React.Fragment>
                <p/>
                <h6 className="d-inline">{t('keywords')}</h6>
                <p className="d-inline">{media.keywords.join(', ')}</p>
                <p/>
              </React.Fragment>
              }
              {media.seasons &&
              <React.Fragment>
                <p/>
                <h6 className="d-inline">{t('seasons')}</h6>
                <p className="d-inline">{media.seasons.join(', ')}</p>
                <p/>
              </React.Fragment>
              }
              <h6>{t('overview')}</h6>
              <p>{media.overview}</p>
            </div>
          </div>
          {media.youtube_link &&
          <React.Fragment>
            <hr className="mt-0"/>
            <div className="container text-center">
              <h5>{t('trailer')}</h5>
              <div className="mdb-youtube-video-container text-center">
                <iframe width="560" height="315" src={youtubeLink}
                        title={media.title + ' video player'} frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                />
              </div>
            </div>
          </React.Fragment>
          }
          <hr/>
          {
            tmdbActive() && media.cast && media?.cast?.length > 0 && <Container>
              <h5 className="text-center">Cast</h5>
              <CastComponent cast={media.cast}/>
            </Container>
          }
        </div>
      }

    </React.Fragment>

  );
};

export default MediaComponent;