import React, { useEffect, useState } from 'react';
import { useRequireAuth } from '../hooks/UseRequireAuth.js';
import { Container, Spinner, Table } from 'react-bootstrap';
import { useError } from '../contexts/ErrorContext.js';
import { useTranslation } from 'react-i18next';
import { getUsersTable } from '../apis/AxiosLaravel.js';
import { faEdit, faTrash, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaginationComponent from '../components/media/PaginationComponent.js';
import DeleteModalComponent from '../components/DeleteModalComponent.js';
import EditAddUserModalComponent from '../components/EditAddUserModalComponent.js';

/**
 * Component for the user management page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UserManagement = (props) => {
  const auth = useRequireAuth(['Admin']);
  const [users, setUsers] = useState({ res: null, status: false, adminCount: 1 });
  const [errorUsersFetch, setErrorUsersFetch] = useState(false);
  const [page, setPage] = useState(1);
  const [deleteShow, setDeleteShow] = useState({ type: 'user', show: false, title: '', id: '' });
  const [editShow, setEditShow] = useState({ show: false, userData: '', type: 'edit' });
  const { t } = useTranslation('general');
  const errorContext = useError();

  const tableHeaders = [
    t('user-management.table.username-header'),
    t('user-management.table.role-header'),
    t('user-management.table.edit-header'),
    t('user-management.table.delete-header')
  ];


  const updatePage = (pageNumber) => {
    setPage(pageNumber);
  };

  /**
   * Method to load the users
   */
  const loadUsers = () => {
    setUsers({
      ...users,
      status: false,
    });

    getUsersTable(page)
      .then(res => {
        const userCount = res?.data?.filter(user => user.role === 'Admin').length;
        setUsers({ res, status: true, adminCount: userCount });
      })
      .catch(error => {
        setUsers({
          ...users,
          status: true,
        });

        setErrorUsersFetch(true);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };


  /**
   * Use effect that is executed every page change and on first load
   */
  useEffect(() => {
    loadUsers();
  }, [page]);

  if (!auth) {
    return (
      <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <React.Fragment>
        <Container id="mdb-user-table">
          {
            errorContext.alert()
          }
          <h2 className="mdb-page-header mdb-card-style mt-3 p-2">{t('media-management-page-header')}</h2>
          <Container className="mdb-user-table mdb-card-style mb-3">
            {
              !users.status && !errorUsersFetch && <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
                <span className="sr-only">Loading...</span>
              </Spinner>
            }

            {
              users.status &&
              <Table striped responsive>
                <thead className="text-center">
                <tr>
                  <th>Id</th>
                  {tableHeaders.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {
                  users.res?.data?.map((el, index) => {

                    return (
                      <tr key={index}>
                        <td>{el.id}</td>
                        <td>{el.username}</td>
                        <td>{el.role}</td>
                        <td className="mdb-table-edit" aria-label={`Edit ${el.title}`}
                            onClick={() => setEditShow({ show: true, userData: el, type: 'edit' })}><FontAwesomeIcon
                          icon={faEdit}/>
                        </td>
                        {((users.adminCount > 1 && el.role === 'Admin') || el.role !== 'Admin') ? (<td className="mdb-table-delete" aria-label={`Delete ${el.username}`}
                             onClick={() => setDeleteShow({
                               ...deleteShow,
                               show: true,
                               title: el.username,
                               param: el.username,
                               type: 'user'
                             })}><FontAwesomeIcon
                          icon={faTrash}/></td>)
                          : (<td className="mdb-table-last-admin text-center">{t('user-management.table.last-admin')}</td>)
                        }

                      </tr>
                    );
                  })
                }
                <tr><td colSpan="5" aria-controls="button" className="mdb-table-add-new" onClick={() => setEditShow({ show: true, userData: null, type: 'create'  })}><FontAwesomeIcon className="mr-2"
                  icon={faUserPlus}/>{t('user-management.table.add-user')}</td></tr>
                </tbody>
              </Table>
            }
          </Container>
          <PaginationComponent response={users?.res} update={updatePage}/>
        </Container>
        <EditAddUserModalComponent
          type={editShow.type}
          userData={editShow.userData}
          reloadUsers={loadUsers}
          show={editShow.show}
          onHide={() => setEditShow({
            show: false,
            media: null,
          })}/>
        <DeleteModalComponent
          type={deleteShow.type}
          title={deleteShow.title}
          param={deleteShow.param}
          reloadMedia={loadUsers}
          show={deleteShow.show}
          onHide={() => setDeleteShow({
            show: false,
            title: '',
            id: null,
          })}
        />
      </React.Fragment>

    );
  }
};

export default UserManagement;