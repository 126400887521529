import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';

/**
 * Wrapper component for the react dropzone package
 * @param handleDroppedFile
 * @param disabled
 * @param isValid
 * @param isInvalid
 * @returns {JSX.Element}
 * @constructor
 */
const DropzoneComponent = ({ handleDroppedFile, disabled= false, isValid = null, isInvalid = null }) => {
  const { t } = useTranslation('media');

  const onDrop = useCallback(acceptedFiles => {
    handleDroppedFile(acceptedFiles[0]);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: 'image/jpeg, image/png, image/webp',
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    disabled: disabled,
    onDrop,
  });

  const files = acceptedFiles.map(file => (
    <Badge pill variant="light" key={file.path}>
      {file.path} - {file.size} bytes
    </Badge>
  ));

  const getDragClass = () => {
    let styles = '';
    if (isDragActive) {
      styles += ' activeStyle';
    }

    if (isDragAccept) {
      styles += ' acceptStyle';
    }

    if (isDragReject) {
      styles += ' rejectStyle';
    }

    return styles;
  };

  return (
    <section className="mdb-drag-and-drop-section">
      <div {...getRootProps({ className: `form-control mdb-drag-and-drop  ${getDragClass()} ${isValid && !isDragActive ? 'is-valid'  : ''} ${isValid !== null && !isDragActive ? !isInvalid ? 'is-invalid' : '' : ''}` })}>
        <input {...getInputProps()}/>
        <p>{t('dropzone.drag-field-text')}</p>
        <button type="button" onClick={open}>
          {t('dropzone.open-file-dialog')}
        </button>
      </div>
      <div className={`invalid-feedback`}>
        {t('form-feedback.poster')}
      </div>
      <aside className="mt-1">
        <h6>{t('dropzone.uploaded-files')}</h6>
        {files}
      </aside>
    </section>
  );
};

export default DropzoneComponent;