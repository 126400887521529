import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';

/**
 * Wrapper for the typeahead boostrap package for season selection
 * @param handleSelected
 * @param preSelected
 * @param isInvalid
 * @param isValid
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SeasonTypeAhead = ({ handleSelected, preSelected, isInvalid, isValid, ...props }) => {
  const { t } = useTranslation('media');
  const seasons = [ ...Array(100).keys() ].map(( i) => `${i+1}`);
  const [selected, setSelected] = useState([]);

  const handleSelection = (selected) => {
    setSelected(selected);
    handleSelected(selected);
  }

  useEffect(() => {
    if (preSelected?.length > 0) {
      setSelected(preSelected);
    }
  }, [preSelected]);

  return(
    <Form.Group>
      <Form.Label>{t('add-media.season-label')}</Form.Label>
      <Typeahead
        id="mdb-season-select"
        multiple
        placeholder={t('add-media.season-placeholder')}
        onChange={handleSelection}
        defaultSelected={preSelected}
        selected={selected}
        options={seasons}
        isValid={isValid}
        isInvalid={isInvalid}
        positionFixed
      />
    </Form.Group>
  )
};

export default SeasonTypeAhead;