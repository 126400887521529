import React, { useState } from 'react';
import { Dropdown, Nav, Navbar, NavDropdown, NavLink } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faSearch } from '@fortawesome/free-solid-svg-icons';
import LoginModalComponent from './LoginModalComponent.js';
import LoggedInUserNav from './LoggedInUserNav.js';
import { useAuth } from '../contexts/AuthContext.js';

/**
 * Header component
 * @returns {JSX.Element}
 * @constructor
 */
const Header = () => {
  const auth = useAuth();
  const [loginShow, setLoginShow] = useState(false);
  const { t } = useTranslation('header');

  return (
    <React.Fragment>
      <Navbar collapseOnSelect id="mdb-header" db="dark" expand="md" variant="dark">
        <LinkContainer to="/">
          <Navbar.Brand id="mdb-home">MDb</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="mdb-header-nav-collapse"/>
        <Navbar.Collapse id="mdb-header-nav-collapse">
          <Nav defaultActiveKey="/news" className="mr-md-auto" as="ul">
            <Nav.Item as="li">
              <LinkContainer to="/news">
                <Nav.Link>{t('home-link')}</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Dropdown className="ml-md-4" as="li">
              <Dropdown.Toggle as={NavLink}>{t('media-link')}</Dropdown.Toggle>
              <Dropdown.Menu className="mdb-header-nav-media" align={{ sm: 'left' }} as="ul">
                <Nav.Item as="li">
                  <LinkContainer to="/media/a-z">
                    <NavDropdown.Item>{t('a-to-z-link')}</NavDropdown.Item>
                  </LinkContainer>
                </Nav.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <Nav id="mdb-login" as="ul" className="justify-content-end">
            <Nav.Item>
              <LinkContainer to="/media/search">
                <Nav.Link><FontAwesomeIcon icon={faSearch}/></Nav.Link>
              </LinkContainer>
            </Nav.Item>

          {
            !auth.user ?
              <Nav.Item as="li">
                <Nav.Link
                  id="mdb-nav-button"
                  onClick={(e) => {e.preventDefault(); setLoginShow(true);}}
                ><FontAwesomeIcon icon={faKey}/></Nav.Link>
              </Nav.Item>
             : <LoggedInUserNav />
          }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <LoginModalComponent
        show={loginShow}
        onHide={() => setLoginShow(false)}
      />
    </React.Fragment>

  );
};

export default Header;
