import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useError } from '../../contexts/ErrorContext.js';
import { getFSK, getGenre, getLocations, getMediums } from '../../apis/AxiosLaravel.js';
import { Alert, Button, Modal } from 'react-bootstrap';
import { tmdbActive } from '../../utils/addUpdateMedia.js';
import AddEditMediaForm from './AddEditMediaForm.js';

/**
 * Modal component for editing media
 * @param media To be edited media
 * @param reloadMedia Function that reloads the media data on change of the media
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EditModalComponent = ({ media = '', reloadMedia, ...props }) => {
  const { t } = useTranslation('media');
  const errorContext = useError();
  const [modalError, setModalError] = useState(false);
  const [laravelMediums, setLaravelMediums] = useState([]);
  const [laravelGenre, setLaravelGenre] = useState([]);
  const [laravelFSK, setLaravelFSK] = useState([]);
  const [laravelLocations, setLaravelLocations] = useState([]);
  const [sendMedia, setSendMedia] = useState(false);
  const [reset, setReset] = useState(false);

  const [data, setData] = useState({
    type: '',
    title: '',
    release_date: '',
    overview: '',
    poster_path: '',
    tmdb_id: null,
    youtube_link: '',
    age_rating: null,
    mediums: [],
    genres: [],
    cast: [],
    location: '',
    seasons: []
  });

   /**
   * Effect that is executed on show of the modal
   */
    useEffect(() => {      
      setSendMedia(false)
      setReset(true);
      setReset(false)
    }, [props.show]);
      

  /**
   * Effect executed on load of the component and change of media
   */
  useEffect(() => {
    if (media) {
      const { id, ...rest } = media;
      setData({
        ...data,
        ...rest,
        seasons: rest?.seasons ? rest?.seasons : [],
        youtube_link: rest.youtube_link ? 'https://www.youtube.com/watch?v=' + rest.youtube_link : '',
      });
      setSendMedia(false)
      setReset(true);
    }

    getMediums().then(mediums => {
      setLaravelMediums(mediums);
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });

    getGenre().then(genres => {
      setLaravelGenre(genres);
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
    getFSK().then(fsks => {
      setLaravelFSK(fsks);
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
    getLocations().then(res => {
      if (res.status === 200) {
        const locations = res.data.map(location => location.location);
        setLaravelLocations(locations);
      }
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  }, [media]);



  const editMedia = () => {
    if (tmdbActive() && media.tmdb_id) {
      setSendMedia(true);
    }
  };

  const hideError = () => {
    setModalError(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="true"
      centered
      onExiting={() => {
        setModalError(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {t('edit-modal.title', { title: media?.title })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          modalError &&
          <Alert variant="danger" onClick={hideError} dismissible>{t('edit-modal.error')}</Alert>
        }
        {
          tmdbActive() && media?.tmdb_id &&
          <AddEditMediaForm mediums={laravelMediums} genres={laravelGenre} laravelFSK={laravelFSK} laravelLocations={laravelLocations} media={media} reset={reset} sendMedia={sendMedia} errorFk={setModalError} reloadMedia={reloadMedia} editModal={true} onHide={props.onHide}/>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="outline-primary" onClick={editMedia}>{t('edit-modal.button')}</Button>
        <Button variant="outline-danger"
                onClick={() => {
                  setReset(true);
                  props.onHide();
                }}
        >{t('edit-modal.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModalComponent;