import React, { useEffect, useState } from 'react';
import { useRequireAuth } from '../hooks/UseRequireAuth.js';
import { Container, Spinner, Table } from 'react-bootstrap';
import { useError } from '../contexts/ErrorContext.js';
import { useTranslation } from 'react-i18next';
import { getStatistics } from '../apis/AxiosLaravel.js';
import PaginationComponent from '../components/media/PaginationComponent.js';

/**
 * Component for the statistics page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Statistics = (props) => {
  const auth = useRequireAuth(['Admin', 'Creator']);
  const [data, setData] = useState({ res: null, status: false });
  const [errorMediaFetch, setErrorMediaFetch] = useState(false);
  const [page, setPage] = useState(1);
  const { t, i18n } = useTranslation('general');
  const errorContext = useError();

  const tableHeaders = [
    t('statistic.month-header'),
    t('statistic.year-header'),
    t('statistic.action-header'),
    t('statistic.count-header'),
  ];


  const updatePage = (pageNumber) => {
    setPage(pageNumber);
  };

  /**
   * Method to transform month numbers to their localized strings
   * @param month
   * @returns {string}
   */
  const transformMonth = (month) => {
    const date = new Date(`${month}/1/2000`);
    return date.toLocaleString(i18n.language, { month: "long" });
  }

  /**
   * Method to load the statistic data
   */
  const loadStatistics = () => {
    setData({
      ...data,
      status: false,
    });
    getStatistics(page)
      .then(res => {
        setData({ res, status: true });
      })
      .catch(error => {
        setData({
          ...data,
          status: true,
        });
        setErrorMediaFetch(true);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  /**
   * Use effect that is executed every page change and on first load
   */
  useEffect(() => {
    loadStatistics();
  }, [page]);

  if (!auth) {
    return (
      <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <React.Fragment>
        <Container id="mdb-media-table">
          {
            errorContext.alert()
          }
          <h2 className="mdb-page-header mdb-card-style mt-3 p-2">{t('statistic-page-header')}</h2>
          <Container className="mdb-media-table mdb-card-style mb-3">
            {
              !data.status && !errorMediaFetch && <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
                <span className="sr-only">Loading...</span>
              </Spinner>
            }

            {
              data.status &&
              <Table striped responsive>
                <thead className="text-center">
                <tr>
                  {tableHeaders.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {
                  data.res?.data?.map((el, index) => {

                    return (
                      <tr key={index} className="text-center">
                        <td>{transformMonth(el.month)}</td>
                        <td>{el.year}</td>
                        <td>{el.action}</td>
                        <td>{el.count}</td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </Table>
            }
          </Container>
          <PaginationComponent response={data?.res} update={updatePage}/>
        </Container>
      </React.Fragment>

    );
  }
};

export default Statistics;