/**
 * Function that returns class for corresponding fsk rating
 * @param ageRating
 * @returns {string}
 */
const getFSKClass = (ageRating) => {
  let fskClass;

  switch (ageRating) {
    case '6':
      fskClass = 'mdb-fsk6';
      break;
    case '12':
      fskClass = 'mdb-fsk12';
      break;
    case '16':
      fskClass = 'mdb-fsk16';
      break;
    case '18':
      fskClass = 'mdb-fsk18';
      break;
    default:
      fskClass = 'mdb-fsk0';
  }

  return fskClass;
}

/**
 * Converts genre array to displayable string
 * @param genres
 * @param t i18next function for internationalization
 * @returns {*} String
 */
const genreDisplay = (genres, t) => {
  return genres.map(genre => t(`media:genre.${genre}`)).join(', ');
}

/**
 * Creates poster source from posterpath
 * @param tmdbID
 * @param posterPath
 * @param size
 * @returns {string}
 */
const getPosterSRC = (tmdbID, posterPath, size) => {
  let imgSrc;
  if (tmdbID && !posterPath.includes('base64') && !posterPath.includes('storage')) {
    imgSrc = `https://www.themoviedb.org/t/p/${size ? size : 'w185'}${posterPath}`;
  } else {
    imgSrc = posterPath;
  }

  return imgSrc

}

export {
  getFSKClass,
  genreDisplay,
  getPosterSRC
}