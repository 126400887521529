import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      fallbackLng: 'en',
      debug: false,
      // for all available options read the backend's repository readme file
      loadPath:  () => {
        const host = window.location.hostname;
        let path = `${process.env.PUBLIC_URL}`

        if (!host.includes('localhost')) {
          path = `/mdb`
        }

        path += `/locales/{{lng}}/{{ns}}.json`

        return path;
      }
    },
    ns: ['general', 'errors', 'header', 'media', 'location'],
    defaultNS: 'general',
    lng: "de",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;