import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useError } from '../contexts/ErrorContext.js';
import { addLocation, patchLocation } from '../apis/AxiosLaravel.js';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import Joi from 'joi';

const schema = Joi.object({
  location: Joi.string().required()
}).unknown(true);

/**
 * Modal component for editing media
 * @param type edit or create
 * @param location To be edited media
 * @param reloadMedia Function that reloads the media data on change of the media
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EditAddLocationModalComponent = ({ type, location, reloadMedia, ...props }) => {
  const { t } = useTranslation('location');
  const errorContext = useError();
  const [modalError, setModalError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [validation, setValidation] = useState({
    location: null,
  });
  const [data, setData] = useState({
    location: '',
  });

  /**
   * Effect executed on load of the component and change of media
   */
  useEffect(() => {
    if (location) {
      const { id, ...rest } = location;
      setData({
        ...data,
        ...rest,
      });
    }
  }, [location]);

  /**
   * Function to send the create request
   */
  const sendCreateRequest = () => {
    setIsLoading(true);
    addLocation(data)
      .then(res => {
        if (res.status === 201) {
          setIsLoading(false)
          setDisabled(false);
          resetData();
          resetValidation();
          reloadMedia();
          props.onHide();
        }
      })
      .catch(error => {
        setIsLoading(false)
        resetValidation();
        setDisabled(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  /**
   * Function to send the update request
   */
  const sendUpdateRequest = () => {
    setIsLoading(true)
    patchLocation(location.id, data)
      .then(res => {
        if (res.status === 200) {
          setIsLoading(false)
          setDisabled(false);
          resetData();
          resetValidation();
          reloadMedia();
          props.onHide();
        }
      })
      .catch(error => {
        setIsLoading(false)
        resetValidation();
        setDisabled(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  const resetData = () => {
    setData({
      location: '',
    });
  };

  const resetValidation = () => {
    setValidation({
      location: null,
    });
  };

  /**
   * Handles input changes
   * @param e event object
   */
  const handleChange = e => {
    resetValidation();
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const editAddMedia = () => {
    setDisabled(true);

    const { error } = schema.validate(data, {
      abortEarly: false,
      dateFormat: 'date',
      errors: { label: 'key' },
    });

    const temp = {};
    Object.keys(validation).forEach((key) => {
      const toTest = error?.details?.find(err => err.path.includes(key));
      temp[key] = !toTest;
    });

    if (temp) {
      setValidation({
        ...validation,
        ...temp,
      });
    }

    if (!error) {
      if (type === 'edit') {
        sendUpdateRequest();
      } else if (type === 'create') {
        sendCreateRequest();
      }

    } else {
      console.log(error);
      setModalError(true);
      setDisabled(false);
    }
  };

  const hideError = () => {
    setModalError(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="true"
      centered
      onExiting={() => {
        setModalError(false);
        resetValidation();
        resetData();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {location ? t('edit-add-modal.edit-title', { location: location?.location }) : t('edit-add-modal.add-title')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          modalError &&
          <Alert variant="danger" onClick={hideError} dismissible>{t('modal.error')}</Alert>
        }
        {
          isLoading &&  <Spinner id="mdb-modal-spinner" className="d-block mr-auto ml-auto mb-5 mt-5" animation="border" role="status" size="xl">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
        }
        {!isLoading && <Form id="mdb-create-form" className="p-3">
          <Form.Group controlId="mdb-create-form.formTitle">
            <Form.Label>{t('edit-add-modal.location-label')}</Form.Label>
            <Form.Control
              name="location"
              value={data.location}
              onChange={handleChange}
              type="text"
              disabled={disabled}
              isInvalid={validation.location !== null ? !validation.location : null}
              isValid={validation.location}
            />
            <Form.Control.Feedback type="invalid">
              {t('modal.form-feedback')}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>}
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="outline-primary" disabled={isLoading} onClick={editAddMedia}>
          {type === 'create' ? t('edit-add-modal.add-button') : t('edit-add-modal.edit-button')}
        </Button>
        <Button variant="outline-danger"
                onClick={() => {
                  props.onHide();
                }}
        >{t('edit-add-modal.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAddLocationModalComponent;