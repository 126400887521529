import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header.js';
import Routes from './Routes.js';
import { AuthProvider } from './contexts/AuthContext.js';
import { ErrorProvider } from './contexts/ErrorContext.js';
import Footer from './components/Footer.js';

/**
 * The main component of the frontend application
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
  return (
    <Router basename={'mdb'}>
      <ErrorProvider>
        <AuthProvider>
          <Container id="mdb-main" fluid>
            <Header/>
            <Container id="mdb-route-container" className="pl-0 pr-0">
              <Routes/>
            </Container>
            <Footer/>
          </Container>
        </AuthProvider>
      </ErrorProvider>
    </Router>

  );
}

export default App;
