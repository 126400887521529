import React, { useEffect, useState } from 'react';
import { Container, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useRequireAuth } from '../hooks/UseRequireAuth.js';
import { getFSK, getLocations, getMediums } from '../apis/AxiosLaravel.js';
import { useError } from '../contexts/ErrorContext.js';
import TmdBadd from '../components/media/TMDBadd.js';
import { tmdbActive } from '../utils/addUpdateMedia.js';
import ManualAdd from '../components/media/ManualAdd.js';
import AddEditMediaForm from '../components/media/AddEditMediaForm.js';

/**
 * Component for adding new media
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddMedia = (props) => {
  const auth = useRequireAuth(['Admin', 'Creator']);
  const { t } = useTranslation('media');
  const errorContext = useError();
  const [laravelMediums, setLaravelMediums] = useState([]);
  const [laravelFSK, setLaravelFSK] = useState([]);
  const [laravelLocations, setLaravelLocations] = useState([]);

  /**
   * Called only ones on load, to get all mediums
   */
  useEffect(() => {
    getMediums().then(mediums => {
      setLaravelMediums(mediums);
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
    getFSK().then(fsks => {
      setLaravelFSK(fsks);
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log('ManualAdd');
          console.log(error);
        }
      });
    getLocations().then(res => {
      if (res.status === 200) {
        const locations = res.data.map(location => location.location);
        setLaravelLocations(locations);
      }
    })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  }, []);

  if (!auth) {
    return (
      <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <React.Fragment>
        <Container id="mdb-add-media">
          {
            errorContext.alert()
          }
          <h2 className="mdb-page-header mdb-card-style mt-3 p-2">{t('add-media.header')}</h2>

          <Container className="mdb-add-media-form">
            {
              laravelMediums.length === 0 && <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
                <span className="sr-only">Loading...</span>
              </Spinner>
            }
            {
              laravelMediums.length !== 0 && tmdbActive() && <Tabs defaultActiveKey="tmdb" id="mdb-add-media-tab">
                <Tab eventKey="tmdb" title={t('tmdb-tab')}>
                  <TmdBadd mediums={laravelMediums} laravelFSK={laravelFSK} laravelLocations={laravelLocations}/>
                </Tab>
                <Tab eventKey="manual" title={t('manual-tab')}>
                  <ManualAdd key={Math.random()} mediums={laravelMediums} laravelFSK={laravelFSK} laravelLocations={laravelLocations}/>
                </Tab>
              </Tabs>
            }
            {
              !tmdbActive() && <AddEditMediaForm mediums={laravelMediums} laravelFSK={laravelFSK} laravelLocations={laravelLocations}/>
            }
          </Container>
        </Container>
      </React.Fragment>
    );
  }
};

export default AddMedia;