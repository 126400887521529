import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext.js';
import { useRouter } from './UseRouter.js';

const UseRequireAuth = (roles = [], redirectUrl = '/') => {
  const [check, setCheck] = useState(false)
  const auth = useAuth();
  const router = useRouter();

  /**
   * Checks if user is logged in an has needed roles if needed
   */
  useEffect(() => {
    if (auth.user === false) {
      router.push(redirectUrl);
    } else if (auth.user !== null && roles.length !== 0 && !roles?.includes(auth.user?.role)) {
      router.push(redirectUrl);
    } else if (auth.user) {
      setCheck(true);
    }
  }, [auth, router]);
  return check;
};

export { UseRequireAuth as useRequireAuth };