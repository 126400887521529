import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { idSearch } from "../../apis/AxiosTMDB.js";
import {
  handleCheckBoxes,
  validateData,
  getKeyFromYoutubeLink,
} from "../../utils/addUpdateMedia.js";
import { useTranslation } from "react-i18next";
import { useError } from "../../contexts/ErrorContext.js";
import { axiosLaravelAPI } from "../../apis/AxiosLaravel.js";
import { useRouter } from "../../hooks/UseRouter.js";
import SeasonTypeAhead from "./SeasonTypeAhead.js";
import TMDBTitleTypeAhead from "./TMDBTitleTypeAhead.js";
import TMDBKeywordTypeAhead from "./TMDBKeywordTypeAhead.js";

/**
 * Component for media adding with the tmdb
 * @param mediums
 * @returns {JSX.Element}
 * @constructor
 */
const TmdBadd = ({ mediums, laravelFSK, laravelLocations }) => {
  const { t } = useTranslation("media");
  const router = useRouter();
  const errorContext = useError();
  const [disabled, setDisabled] = useState(false);
  const [validation, setValidation] = useState({
    type: null,
    title: null,
    age_rating: null,
    mediums: null,
    seasons: null,
    location: null,
  });
  const [data, setData] = useState({
    type: null,
    title: null,
    release_date: null,
    overview: null,
    poster_path: null,
    tmdb_id: null,
    youtube_link: null,
    age_rating: null,
    mediums: [],
    genres: [],
    cast: [],
    location: null,
    keywords: [],
  });

  const resetValidation = () => {
    setValidation({
      type: null,
      title: null,
      age_rating: null,
      mediums: null,
      seasons: null,
      location: null,
    });
  };

  /**
   * Gets all the movie information from tmdb and bundles them
   * into one object, then set the state
   * @param keywords
   */
  const handleKeywordSearchValue = (selected) => {
    resetValidation();
    setData({
      ...data,
      keywords: selected,
    });
  };

  const sendCreateRequest = (data) => {
    axiosLaravelAPI()
      .put("/media", data)
      .then((res) => {
        if (res.status === 201) {
          resetValidation();
          router.push("/media/" + res.data.id);
        }
      })
      .catch((error) => {
        resetValidation();
        setDisabled(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  /**
   * Gets all the movie information from tmdb and bundles them
   * into one object, then set the state
   * @param media
   */
  const handleTitleSearchValue = (media) => {
    resetValidation();
    media = media[0];
    if (media?.tmdb_id) {
      idSearch(data?.type, media)
        .then((res) => {
          setData({ ...data, ...res });
        })
        .catch((error) => {
          if (!error.response) {
            errorContext.setNetworkError();
          } else if (error.response.status === 401) {
            errorContext.setTMDB401Error();
          } else {
            errorContext.setUnexpectedError();
            console.log(error);
          }
        });
    }
  };

  const handleSeasons = (seasons) => {
    setData({
      ...data,
      seasons,
    });
  };

  /**
   * Handles input changes
   * @param e event object
   */
  const handleChange = (e) => {
    resetValidation();

    if (e.target.name === "type") {
      setData({
        ...data,
        title: "",
      });
    }

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckbox = (e) => {
    resetValidation();
    handleCheckBoxes(e.target.name, e.target.value, data, setData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDisabled(true);

    const error = validateData(data);

    const temp = {};
    Object.keys(validation).forEach((key) => {
      const toTest = error?.details?.find((err) => err.path.includes(key));
      temp[key] = !toTest;
    });

    if (temp) {
      setValidation({
        ...validation,
        ...temp,
      });
    }

    if (!error) {
      setData({
        ...data,
        youtube_link: getKeyFromYoutubeLink(data.youtube_link),
      });

      // Workaround to set the youtube link, because setData isn´t executed properly
      data.youtube_link = getKeyFromYoutubeLink(data.youtube_link);

      sendCreateRequest(data);
    } else {
      console.log(error);
      setDisabled(false);
    }
  };

  return (
    <Form
      id="mdb-create-form"
      className="p-3"
      noValidate
      onSubmit={handleSubmit}
    >
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>{t("add-media.select-label")}</Form.Label>
        <Form.Control
          required
          name="type"
          value={data.type}
          onChange={handleChange}
          as="select"
          disabled={disabled}
          isInvalid={validation.type !== null ? !validation.type : null}
          isValid={validation.type}
        >
          <option />
          <option value="Movie">{t("add-media.select-movie")}</option>
          <option value="TV">{t("add-media.select-tv")}</option>
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {t("form-feedback.type")}
        </Form.Control.Feedback>
      </Form.Group>

      {data?.type && (
        <Form.Group controlId="formBasicTMDBSearch">
          <Form.Label>
            {t("add-media.search-for-label", {
              type:
                data?.type === "TV"
                  ? t("add-media.select-tv")
                  : t("add-media.select-movie"),
            })}
          </Form.Label>
          <TMDBTitleTypeAhead
            type={data?.type}
            handleSearchValue={handleTitleSearchValue}
            resetValidation={resetValidation}
            placeholcer={t("add-media.title-search-placeholder", {
              type:
                data?.type === "TV"
                  ? t("add-media.select-tv")
                  : t("add-media.select-movie"),
            })}
            disabled={disabled}
            preSelected={data?.title ? [data] : []}
            isInvalid={validation.title !== null ? !validation.title : null}
            isValid={validation.title}
          />
          <Form.Control.Feedback
            type="invalid"
            style={{
              display: `${
                validation.title !== null
                  ? !validation.title
                    ? "block"
                    : "none"
                  : "none"
              }`,
            }}
          >
            {t("form-feedback.title-select")}
          </Form.Control.Feedback>
        </Form.Group>
      )}

      {data?.type?.toLowerCase() === "tv" && (
        <SeasonTypeAhead
          handleSelected={handleSeasons}
          isValid={validation.seasons}
          isInvalid={validation.seasons !== null ? !validation.seasons : null}
        />
      )}

      {data?.type && data?.title && (
        <Form.Group controlId="mdb-create-form.formFsk">
          <Form.Label>
            {t("add-media.fsk-label", {
              type:
                data?.type === "TV"
                  ? t("add-media.select-tv")
                  : data?.type === "Movie"
                  ? t("add-media.select-movie")
                  : "Media",
            })}
          </Form.Label>
          <Form.Control
            required
            name="age_rating"
            value={data?.age_rating}
            onChange={handleChange}
            as="select"
            disabled={disabled}
            isInvalid={
              validation.age_rating !== null ? !validation.age_rating : null
            }
            isValid={validation.age_rating}
          >
            <option value="" />
            {laravelFSK?.map((fsk) => (
              <option key={fsk} value={fsk}>
                {fsk}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      <Form.Group controlId="mdb-create-form.formLocation">
        <Form.Label>
          {t("add-media.location-label", {
            type:
              data?.type === "TV"
                ? t("add-media.select-tv")
                : data?.type === "Movie"
                ? t("add-media.select-movie")
                : "Media",
          })}
        </Form.Label>
        <Form.Control
          required
          name="location"
          value={data.location}
          onChange={handleChange}
          as="select"
          disabled={disabled}
          isInvalid={validation.location !== null ? !validation.location : null}
          isValid={validation.location}
        >
          <option value="" />
          {laravelLocations?.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group required controlId="formMediums" className="mb-3">
        <Form.Label>{t("add-media.select-mediums-label")}</Form.Label>
        <div>
          {mediums?.map((medium) => (
            <Form.Check
              key={medium}
              inline
              label={medium}
              name="mediums"
              onChange={handleCheckbox}
              type="checkbox"
              value={medium}
              checked={data?.mediums?.includes(medium)}
              id={`inline-checkbox-${medium.toLowerCase()}`}
              disabled={disabled}
              isInvalid={
                validation.mediums !== null ? !validation.mediums : null
              }
              isValid={validation.mediums}
            />
          ))}
          <Form.Control.Feedback
            type="invalid"
            style={{
              display: `${
                validation.mediums !== null
                  ? !validation.mediums
                    ? "block"
                    : "none"
                  : "none"
              }`,
            }}
          >
            {t("form-feedback.mediums")}
          </Form.Control.Feedback>
        </div>
      </Form.Group>

      {data?.type && data?.title && (
        <Form.Group controlId="formBasicTMDBSearch">
          <Form.Label>{t("add-media.search-for-keywords-label")}</Form.Label>
          <TMDBKeywordTypeAhead
            key={data?.title + "-cast-typeahead"}
            handleSearchValue={handleKeywordSearchValue}
            preSelected={data?.keywords ? data?.keywords : []}
            resetValidation={resetValidation}
            disabled={disabled}
            placeholcer={t("add-media.keyword-search-placeholder")}
          />
        </Form.Group>
      )}

      <Button
        className="mdb-add-media-form-submit mt-3"
        variant="primary"
        type="submit"
        disabled={disabled}
      >
        {t("add-media.submit-button", {
          type:
            data?.type === "TV"
              ? t("add-media.select-tv")
              : data?.type === "Movie"
              ? t("add-media.select-movie")
              : "Media",
        })}
      </Button>
    </Form>
  );
};

export default TmdBadd;
