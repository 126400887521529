import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useError } from '../contexts/ErrorContext.js';
import { useTranslation } from 'react-i18next';
import { deleteType } from '../apis/AxiosLaravel.js';

/**
 * Component that represents the delete modal of the site
 * @param type the type to be deleted
 * @param title the title of the element that should be deleted
 * @param param request parameters
 * @param reloadMedia method that reloads media
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DeleteModalComponent = ({ type, title, param, reloadMedia, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const errorContext = useError();
  const { t } = useTranslation();

  const deleteMedia = () => {
    setIsLoading(true);
    deleteType(type, param)
      .then(res => {
        setIsLoading(false);
        if (res.status === 200) {
          reloadMedia();
          props.onHide();
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      })
  }

  return(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('delete-modal.title', {title: title})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {
            isLoading &&  <Spinner id="mdb-modal-spinner" className="d-block mr-auto ml-auto mb-5 mt-5" animation="border" role="status" size="xl">
                              <span className="sr-only">Loading...</span>
                          </Spinner>
          }
          {!isLoading &&
            <p className="delete-modal-text">{t('delete-modal.text', {title: title})}</p>
          }
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="outline-primary" disabled={isLoading} onClick={deleteMedia}>{t('delete-modal.button')}</Button>
        <Button variant="outline-danger"
                onClick={() => {
                  props.onHide();
                }}
        >{t('delete-modal.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  )

};

export default DeleteModalComponent;