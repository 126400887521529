import React, { useCallback, useState } from 'react';
import { AsyncTypeahead, ClearButton } from 'react-bootstrap-typeahead';
import { useError } from '../../contexts/ErrorContext.js';
import { searchKeyword } from '../../apis/AxiosLaravel.js';

const CACHE = {};
const PER_PAGE = 10;

/**
 * Wrapper for the typeahead boostrap package for cast selection
 * @param preSelected
 * @param disabled
 * @param handleSearchValue
 * @param placeholcer
 * @param resetValidation
 * @param isValid
 * @param isInvalid
 * @returns {JSX.Element}
 * @constructor
 */
const TMDBKeywordTypeAhead = ({
                         preSelected,
                         disabled = false,
                         handleSearchValue,
                         placeholcer = 'Search ...',
                         resetValidation,
                         isValid,
                         isInvalid,
                       }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [query, setQuery] = useState('');
  const errorContext = useError();

  const handleSelect = (selected) => {
    if (selected.length !== 0) {
      selected = selected.map(el => {
        if (el.customOption) {
          return el.label;
        } else {
          return el;
        } 
      })      
    }
    
    handleSearchValue(selected);
  };

  const handleInputChange = (q) => {
    setQuery(q);
  };

  const formatData = (data) => {
    return data.map(el => {
      return el.keyword;
    })
  }

  const handlePagination = (e, shownResults) => {
    const cachedQuery = CACHE[query];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.data.length > shownResults ||
      cachedQuery.current_page === cachedQuery.total
    ) {
      return;
    }

    setIsLoading(true);

    const page = cachedQuery.current_page + 1;

    searchKeyword(query, page)
    .then(res => {
      const elements = cachedQuery.data.concat(formatData(res.data));
      CACHE[query] = { ...res, data: elements };
      setElements(elements);
      setIsLoading(false);
    })
    .catch(error => {
      if (!error.response) {
        errorContext.setNetworkError();
      } else if (error.response.status === 401) {
        errorContext.setTMDB401Error();
      } else {
        errorContext.setUnexpectedError();
        console.log('TMDBKeywordTypeAhead');
        console.log(error);
      }
    });
  };

  const handleSearch = useCallback((q) => {
    if (CACHE[q]) {
      setElements(formatData(CACHE[q].data));
      return;
    }

    resetValidation();
    setIsLoading(true);

    
    searchKeyword(q)
      .then(res => {
        CACHE[q] = { ...res };
        setElements(formatData(res.data));
        setIsLoading(false);
      })
      .catch(error => {
        if (!error.response) {
          errorContext.setNetworkError();
        } else if (error.response.status === 401) {
          errorContext.setTMDB401Error();
        } else {
          errorContext.setUnexpectedError();
          console.log('TMDBKeywordTypeAhead');
          console.log(error);
        }
      });
  }, []);

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id="mdb-tmdb-typeahead"
      className="mdb-tmdb-typeahead"
      isLoading={isLoading}
      multiple
      allowNew
      maxHeight={'400px'}
      maxResults={PER_PAGE - 1}
      flip
      clearButton
      defaultSelected={preSelected}
      disabled={disabled}
      paginate
      minLength={3}
      onInputChange={handleInputChange}
      onSearch={handleSearch}
      onPaginate={handlePagination}
      onChange={handleSelect}
      options={elements}
      placeholder={placeholcer}
      isInvalid={isInvalid}
      isValid={isValid}
    >
      
    </AsyncTypeahead>
  );
};

export default TMDBKeywordTypeAhead;