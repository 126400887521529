import React from 'react';
import { Container, Pagination } from 'react-bootstrap';
import { getItems } from '../../utils/pagination.js';

/**
 * Component that represents the pagination of the site
 * @param response The response json of the backend
 * @param update method that is called to update the page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaginationComponent = ({response, update, ...props}) => {
  return(
    <Container {...props} className="mdb-card-style d-flex align-items-center justify-content-center mb-3">
        <Pagination id="mdb-pagination" className="m-0">
          {response?.total > 1 && response?.current_page !== 1 && <Pagination.First onClick={() => {update((1))}}/>}
          {response?.total > 1 && response?.current_page !== 1 && <Pagination.Prev onClick={() => {update((response?.current_page - 1))}}/>}
          {response?.total > 0 && getItems(response?.current_page, response?.last_page, update)}
          {response?.total > 1 && response?.current_page !== response?.last_page && <Pagination.Next onClick={() => {update((response?.current_page + 1))}} />}
          {response?.total > 1 && response?.current_page !== response?.last_page && <Pagination.Last onClick={() => {update((response?.last_page))}}/>}
        </Pagination>

    </Container>

  )
};

export default PaginationComponent;