import React, { useEffect, useState } from 'react';
import { Modal, Table, Spinner } from 'react-bootstrap';
import { useError } from '../contexts/ErrorContext.js';
import { useTranslation } from 'react-i18next';
import { getKeywords } from '../apis/AxiosLaravel.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteModalComponent from './DeleteModalComponent.js';
import EditAddKeywordModalComponent from './EditAddKeywordModalComponent.js';
import PaginationComponent from './media/PaginationComponent.js';

/**
 * Component that represents the delete modal of the site
 * @param type the type to be deleted
 * @param reloadMedia method that reloads media
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const KeywordModalComponent = ({ ...props }) => {
  const errorContext = useError();
  const { t } = useTranslation('keyword');
  const [keywords, setKeywords] = useState({ res: null });
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLocationFetch, setErrorLocationFetch] = useState(false);
  const [deleteShow, setDeleteShow] = useState({ show: false, keyword: '' });
  const [editShow, setEditShow] = useState({ show: false, type: null, keyword: null });

  /**
   * Load locations on load
   */
  useEffect(() => {
    if (props.show) {
      loadKeywords();
    }
  }, [page, props.show]);

  /**
   * Wrapper for the setPage useState setter
   * @param pageNumber
   */
  const updatePage = (pageNumber) => {
    setPage(pageNumber);
  };

  /**
   * Method that loads the data for table
   */
  const loadKeywords = () => {
    setIsLoading(true);
    getKeywords(page)
      .then(res => {
        setIsLoading(false);
        setKeywords({ res });
      })
      .catch(error => {
        setIsLoading(false)
        setErrorLocationFetch(true);
        if (!error.response) {
          errorContext.setNetworkError();
        } else {
          errorContext.setUnexpectedError();
          console.log(error);
        }
      });
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('modal.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='mdb-keyword-modal'>
          {
            isLoading &&  <Spinner id="mdb-modal-spinner" className="d-block mr-auto ml-auto mb-5 mt-5" animation="border" role="status" size="xl">
                              <span className="sr-only">Loading...</span>
                          </Spinner>
          }
          {!isLoading && 
          <React.Fragment>
            <Table striped responsive>
              <thead className="text-center">
              <tr>
                <th>{t('table.name')}</th>
                <th>{t('media:table.edit-header')}</th>
                <th>{t('media:table.delete-header')}</th>
              </tr>
              </thead>
              <tbody>
              {
                keywords?.res?.data?.map((el, index) => {

                  return (
                    <tr key={index}>
                      <td className="mdb-table-location">{el.keyword}</td>
                      <td className="mdb-table-edit" aria-label={`Edit ${el.keyword}`}
                          onClick={() => setEditShow({ show: true, type: 'edit', keyword: el.keyword })}><FontAwesomeIcon
                        icon={faEdit}/>
                      </td>
                      <td className="mdb-table-delete" aria-label={`Delete ${el.keyword}`}
                          onClick={() => setDeleteShow({ show: true, keyword: el.keyword })}><FontAwesomeIcon
                        icon={faTrash}/></td>
                    </tr>
                  );
                })
              }
              <tr>
                <td
                  colSpan="12"
                  aria-controls="button"
                  className="mdb-table-add-new"
                  onClick={() => setEditShow({...editShow, show: true, type: 'create' })}
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlusSquare}/>
                  {t('modal.add-new')}
                </td>
              </tr>
              </tbody>
            </Table>
            <PaginationComponent response={keywords.res} update={updatePage}/>
          </React.Fragment>          
          }
        </Modal.Body>
      </Modal>
      <EditAddKeywordModalComponent
        type={editShow.type}
        keyword={editShow.keyword}
        reloadMedia={loadKeywords}
        show={editShow.show}
        onHide={() => setEditShow({
          show: false,
          type: null,
          keyword: null
        })}
      />
      <DeleteModalComponent
        type="keyword"
        title={deleteShow.keyword}
        param={deleteShow.keyword}
        reloadMedia={loadKeywords}
        show={deleteShow.show}
        onHide={() => setDeleteShow({
          show: false,
          title: '',
          id: null,
        })}
      />
    </React.Fragment>

  );

};

export default KeywordModalComponent;