import React, { useState } from 'react';
import { useRequireAuth } from '../hooks/UseRequireAuth.js';
import { useTranslation } from 'react-i18next';
import { useError } from '../contexts/ErrorContext.js';
import { Alert, Button, Col, Container, Form, Spinner } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext.js';
import Joi from 'joi';

/**
 * Validation schema for the password change data
 * @type {Joi.ObjectSchema<any>}
 */
const schema = Joi.object({
  old_password: Joi.string().required(),
  new_password: Joi.string().min(8).required(),
  new_password_confirmation: Joi.string().equal(Joi.ref('new_password')).required(),
});

/**
 * Component for the profile page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Profile = (props) => {
  const auth = useRequireAuth(['Admin', 'Creator']);
  const authContext = useAuth();
  const { t } = useTranslation();
  const errorContext = useError();
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [validation, setValidation] = useState({
    old_password: null,
    new_password: null,
    new_password_confirmation: null,
  });
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  });

  const resetValidation = () => {
    setValidation({
      old_password: null,
      new_password: null,
      new_password_confirmation: null,
    });
  };

  const resetFormData = () => {
    setFormData({
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
    });
  };

  /**
   * Method to handle input changes
   * @param e event object
   */
  const handleChange = (e) => {
    e.preventDefault();
    resetValidation();

    setFormData(prevState => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  /**
   * Method that handles all necessary preparations to send a password change request
   * @param e event object
   */
  const handlePasswordChange = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { error } = schema.validate(formData, {
      abortEarly: false,
      errors: { label: 'key' },
    });

    const temp = {};
    Object.keys(validation).forEach((key) => {
      const toTest = error?.details?.find(err => err.path.includes(key));
      temp[key] = !toTest;
    });

    if (temp) {
      setValidation({
        ...validation,
        ...temp,
      });
    }

    if (!error) {

      const trimmed = {};
      Object.entries(formData).forEach(([key, value]) => {
        trimmed[key] = value.trim();
      });

      setFormData({
        ...formData,
        ...trimmed,
      });

      authContext.changeOwnPassword(formData)
        .then(res => {
          if (res) {
            setPasswordChangeSuccess(true);
            resetValidation();
            resetFormData();
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            setValidation({
                old_password: false,
                new_password: false,
                new_password_confirmation: false,
              },
            );
            if (error.response.status === 401) {
              errorContext.set401Error();
            } else {
              errorContext.setNetworkError();
              resetFormData();
              resetValidation();
            }
          }
        });
    }
  };

  if (!auth) {
    return (
      <Spinner id="mdb-spinner" animation="border" role="status" size="xl">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <React.Fragment>
        <Container id="mdb-profile">
          {
            errorContext.alert()
          }
          {
            passwordChangeSuccess && <Alert variant="success" dismissible onClick={() => {setPasswordChangeSuccess(false)}}>
              {t('profile.password-change-success')}
              </Alert>
          }
          <h2
            className="mdb-page-header mdb-card-style mt-3 p-2">{t('profile-page-header', { username: authContext.user.username })}</h2>
          <Container className="mdb-profile mdb-card-style p-3">
            <h4 className="text-center">{t('profile.change-password')}</h4>
            <Form id="mdb-password-change-form" noValidate onSubmit={handlePasswordChange} onReset={() => {
              resetValidation();
              resetFormData();
            }}>
              <Form.Group controlId="formBasicOldPassword">
                <Form.Label>{t('profile.old-password-label')}</Form.Label>
                <Form.Control required
                              type="password"
                              name="old_password"
                              onChange={handleChange}
                              value={formData.old_password}
                              placeholder={t('profile.old-password-placeholder')}
                              isInvalid={validation.old_password !== null ? !validation.old_password : null}
                              isValid={validation.old_password}
                />
                <Form.Control.Feedback type="invalid">
                  {t('profile.old-password-feedback')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicNewPassword">
                <Form.Label>{t('profile.new-password-label')}</Form.Label>
                <Form.Control required
                              type="password"
                              name="new_password"
                              onChange={handleChange}
                              value={formData.new_password}
                              placeholder={t('profile.new-password-placeholder')}
                              isInvalid={validation.new_password !== null ? !validation.new_password : null}
                              isValid={validation.new_password}
                />
                <Form.Control.Feedback type="invalid">
                  {t('profile.new-password-feedback')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formBasicNewPasswordConfirm">
                <Form.Label>{t('profile.new-password-confirm-label')}</Form.Label>
                <Form.Control required
                              type="password"
                              name="new_password_confirmation"
                              onChange={handleChange}
                              value={formData.new_password_confirmation}
                              placeholder={t('profile.new-password-confirm-placeholder')}
                              isInvalid={validation.new_password_confirmation !== null ? !validation.new_password_confirmation : null}
                              isValid={validation.new_password_confirmation}
                />
                <Form.Control.Feedback type="invalid">
                  {t('profile.new-password-comfirmed-feedback')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Row className="justify-content-center">
                  <Col>
                    <Button className="mdb-profile-change-password-button" variant="outline-primary"
                            type="submit">{t('profile.change-button')}</Button>
                  </Col>
                  <Col>
                    <Button className="mdb-profile-change-password-button" variant="outline-danger"
                            type="reset">{t('profile.reset-button')}</Button>
                  </Col>
                </Form.Row>
              </Form.Group>
            </Form>
          </Container>
        </Container>
      </React.Fragment>
    );
  }
};

export default Profile;