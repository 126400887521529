import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import MediaManagement from './pages/MediaManagement.js';
import FullMedia from './pages/FullMedia.js';
import AddMedia from './pages/AddMedia.js';
import ShowAllMedia from './pages/ShowAllMedia.js';
import Profile from './pages/Profile.js';
import UserManagement from './pages/UserManagement.js';
import Statistics from './pages/Statistics.js';
import SearchMedia from './pages/SearchMedia.js';

/**
 * Component that contains the router switch, to determine which page component should be loaded
 * for which url
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Routes = (props) => {
  return (
      <Switch>
        <Route exact path="/">
          <Redirect to="/news"/>
        </Route>
        <Route path="/news">
          <ShowAllMedia type="news"/>
        </Route>
        <Route path="/media/a-z">
          <ShowAllMedia type="a-z"/>
        </Route>
        <Route path="/media/search">
          <SearchMedia/>
        </Route>
        <Route path="/media/:id">
          <FullMedia/>
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/statistic">
          <Statistics />
        </Route>
        <Route path="/add-media">
          <AddMedia />
        </Route>
        <Route path="/media-management">
          <MediaManagement/>
        </Route>
        <Route path="/user-management">
          <UserManagement />
        </Route>
        <Route path="*">
          <Redirect to="/news"/>
        </Route>
      </Switch>
  );

};
export default Routes;