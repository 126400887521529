import React, { createContext, useContext, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const ErrorContext = createContext([]);

export const ErrorProvider = ({ children }) => {
  const auth = useProvideError();
  return(
    <ErrorContext.Provider value={auth} >
      {children}
    </ErrorContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useError = () => {
  return useContext(ErrorContext);
};

const useProvideError = () => {
  const { t } = useTranslation('errors');
  const [error, setError] = useState({ variant: 'danger', show: false, content: 'network-error' });

  const alert = () => {
   return error.show &&
     <Alert variant={error.variant} onClick={hideError}
            dismissible>
       {t(error.content)}
     </Alert>
  }

  const hideError = () => {
    setError({
      ...error,
      content: 'network-error',
      show: false
    })
  }

  const setNetworkError = () => {
    setError({
      ...error,
      show: true
    })
  }

  const setUnexpectedError = () => {
    setError({
      ...error,
      content: 'unexpected-error',
      show: true
    })
  }

  const set401Error = () => {
    setError({
      ...error,
      content: '401-error',
      show: true
    })
  }

  const setTMDB401Error = () => {
    setError({
      ...error,
      content: 'tmdb-401-error',
      show: true
    })
  }

  const setTMDB404Error = () => {
    setError({
      ...error,
      content: 'tmdb-404-error',
      show: true
    })
  }

  // Return the error object and setError methods
  return {
    error,
    alert,
    hideError,
    setNetworkError,
    setUnexpectedError,
    set401Error,
    setTMDB401Error,
    setTMDB404Error
  }
}